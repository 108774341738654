<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12">
                <NewMeetingComponent/>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import NewMeetingComponent from "@/components/NewMeeting.vue";

export default {
    name: 'NewMeeting',
    components: {
        NewMeetingComponent,
    },
    created() {
        console.log('created');
    }
};
</script>
