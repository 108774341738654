<template>
<div>
    <v-overlay absolute :value="1" :opacity=".5" :z-index="100000">
        <v-snackbar
            tile
            :content-class="`pa-${padding}`"
            v-model="snackbar"
            vertical
            centered
            :timeout="-1"
            >
            <span class="title">{{title}}</span>
            <v-spacer class="mb-5"/>
            <span>{{text}}</span>
            <template v-slot:action="{ attrs }">
                <div :class="`pb-${padding} pr-${padding}`">
                    <v-btn
                        tile
                        small
                        color="primary"
                        v-bind="attrs"
                        @click="confirm">
                        {{btnText}}
                    </v-btn>
                </div>
            </template>
        </v-snackbar>
    </v-overlay>
</div>
</template>

<script>
export default {
    name: "FullScreenError",
    props: ['error'],
    data: () => ({
        snackbar: true,
    }),
    methods: {
        confirm() {
            this.loading = true;
            this.$emit('confirm');
        }
    },
    computed: {
        padding() {
            return 5;
        },
        title() {
          return this.error.title;
        },
        text() {
          return this.error.text;
        },
        btnText() {
          return this.error.btnText;
        },
    }
};
</script>

<style scoped>
.no-margin-top {
  margin-top: 0;
}

.v-navigation-drawer__content .v-list.v-sheet {
  padding-top: 0;
}

.profile-info {
  margin-top: 75px;
  background: rgba(0, 0, 0, 0.37);
  padding: 7px 14px;
  color: #fff;
  position: relative;
  font-size: 13px;
}

.profile-pic {
  padding: 12px;
}

.profile-div {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  background: url(../assets/Observatorio_Bosque_Alegre_02.jpg) no-repeat 0 0;
  background-size: auto;
  background-size: 100%;
}

.active.v-icon {
  /* transform: rotate(-180deg); */
  transform: rotate(180deg) translateY(-1px);
  -webkit-transform: rotate(180deg) translateY(-1px);
}


</style>