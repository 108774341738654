<template>
  <v-card tile elevation=1>
    <v-toolbar :color="headerColor" tile elevation=0>
        <v-toolbar-title class="white--text">
            <slot name="title">
                <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">{{title}}</span>
            </slot>
        </v-toolbar-title>
        <v-spacer/>
        <v-btn
            class="mr-1"
            v-if="closable"
            icon
            color="white"
            x-small
            @click.prevent="close"
        >
            <v-icon >mdi-close</v-icon>
        </v-btn>

    </v-toolbar>
    <v-card-text :class="{'relative': singleFab}">
        <v-speed-dial
            v-if="multiFab"
            class="mr-5"
            :class="{'mt-6': $vuetify.breakpoint.mdAndUp, 'mt-4': $vuetify.breakpoint.smAndDown } "
            v-model="fab"
            top
            right
            absolute
            elevation="0"
            direction="left"
            :transition="transition"
            >
            <template v-slot:activator>
                <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                bottom
                left
                fab
                height="50"
                width="50"
                >
                <v-icon v-if="fab">
                    mdi-close
                </v-icon>
                <v-icon v-else>
                    mdi-dots-vertical
                </v-icon>
                </v-btn>
            </template>
            <v-btn v-for="action in actions" :key="action.event"
                fab
                dark
                small
                :color="action.color"
                @click.prevent="$emit(action.event)"
            ><v-icon>{{action.icon}}</v-icon>
            </v-btn>
        </v-speed-dial>
        <v-btn
            class="fab-action mr-5"
            v-if="singleFab"
            fab
            color="primary"
            top
            right
            absolute
            elevation="0"
            height="50"
            width="50"
            @click.prevent="$emit(singleFab.event);"
        >
            <v-icon>{{singleFab.icon}}</v-icon>
        </v-btn>

        <slot/>
    </v-card-text>
    <slot name="actions" v-if="primaryActionText || secondaryActionText || loading">
        <v-card-actions
            class="pb-5 pr-5">
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
            <template v-else>
                <v-spacer/>
                <v-btn small text @click.prevent="$emit('secondaryAction')">{{secondaryActionText}}</v-btn>
                <v-btn small tile :disabled="primaryActionDisabled" @click.prevent="$emit('primaryAction')" class="primary">{{primaryActionText}}</v-btn>
            </template>
        </v-card-actions>
    </slot>
  </v-card>
</template>

<script>
export default {
    name: "Card",
    data: () => ({
        fab: false,
        transition: 'slide-y-reverse-transition',
    }),
    props: {
        title: String,
        headerColor: String,
        actionBtn: String,
        actions: Array,
        dark: Boolean,
        closable: Boolean,
        primaryActionText: String,
        secondaryActionText: String,
        primaryActionDisabled: Boolean,
        loading: Boolean,
        height: Number,
    },
    computed: {
        multiFab() {
            return !this.singleFab && this.actions?.length;
        },
        singleFab() {
            if (this.actionBtn) {
                return {
                    event: 'actionBtnClicked',
                    color: 'primary',
                    icon: this.actionBtn,
                }
            }
            return this.actions?.length === 1 ? this.actions[0] : null;
        },
    },
    methods: {
        close() {
            this.$emit('close');
        }
    },
};
</script>

<style scoped>
.v-btn.fab-action:hover i {
  -webkit-transform: rotate(1turn);
  transform: rotate(1turn);
}

.relative {
    position: relative !important;
}
</style>