<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="3">
                <v-row>
                    <v-col cols="12">
                        <FilterCard @filterAction="fetch" :loading="loading">
                            <v-form class="pa-5 caption" @submit="fetch">
                                <v-text-field
                                    v-model="filters.title"
                                    label="Título"
                                    class="pt-0"
                                    @keydown.enter="fetch"
                                ></v-text-field>
                                <v-menu
                                v-model="showFromDatePicker"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    clearable
                                    v-model="fromDateFormatted"
                                    label="Fecha desde"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filters.from_date"
                                    color="primary"
                                    no-title
                                ></v-date-picker>
                                </v-menu>

                                <v-menu
                                v-model="showtoDatePicker"
                                :close-on-content-click="true"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    clearable
                                    v-model="toDateFormatted"
                                    label="Fecha hasta"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="filters.to_date"
                                    color="primary"
                                    no-title
                                ></v-date-picker>
                                </v-menu>
                            </v-form>
                        </FilterCard>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="10">
                            <v-btn
                                to="nueva"
                                tile
                                block
                                color="primary"
                                class="caption"
                            >
                                <span v-if="!loading">
                                    <v-icon small class="mb-1">mdi-plus</v-icon>
                                    Crear reunión
                                </span>
                            </v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="7" :class="{ 'pl-4': $vuetify.breakpoint.mdAndUp }">
                <Card header-color="cardHeader" dark action-btn="mdi-calendar" @actionBtnClicked="add" title="reuniones">
                    <!-- <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Reuniones</span>
                    </template> -->
                    <GenericTable :headers="headers" :data="data" :loading="loading" :loading-delete="loadingDelete" @delete="deleteItem"
                        :canDeleteItem="canDeleteItem" @view="viewItem">
                        <template v-slot:item.datetime="{ item }">
                            <span>{{ formatMeetingDate(item) }}</span>
                        </template>
                    </GenericTable>
                </Card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import FilterCard from "@/components/FilterCard.vue";
import Card from "@/components/Card.vue";
import GenericTable from "@/components/GenericTable.vue";
import { mapActions, mapState } from "vuex";
import utils from '@/utils/utils';

export default {
    name: "UserList",
    components: {
        GenericTable,
        Card,
        FilterCard,
    },
    data: () => ({
        showFromDatePicker: false,
        showtoDatePicker: false,
        filters: {
            username: undefined,
            topic: undefined,
            from_date: undefined,
            to_date: undefined,
        },
        loading: true,
        headers: [
            {
                text: "Título",
                align: "start",
                sortable: false,
                value: "title",
                width: "50%",
            },
            {
                text: "Fecha",
                align: "start",
                sortable: false,
                value: "datetime",
                width: "15%",
            },
            {
                text: "Organizador",
                align: "start",
                sortable: false,
                value: "owner.username"
            },
            
            { text: "", value: "actions", sortable: false, align: "end" }
        ],
        loadingDelete: false,
        currentTimeByMinute: new Date(),
    }),
    async created() {
        setInterval(() => this.currentTimeByMinute = new Date(), 1000);
        await this.fetch();
    },
    methods: {
        ...mapActions(['fetchMeetings', 'deleteMeeting']),
        async fetch() {
            try {
                this.loading = true;
                await this.fetchData(this.filters);
            } catch (err) {
                console.log('fetch err', err);
            } finally {
                this.loading = false;
            }
        },
        add() {
            this.$router.push('/reuniones/calendario');
        },
        viewItem(item) {
            this.$router.push(`/reuniones/${item.id}`);
        },
        async deleteItem(item) {
            this.loadingDelete = true;
            try {
                await utils.timeout(500);
                await this.deleteMeeting(item.id);
                const index = this.items.findIndex(e => e.id === item.id);
                if (index !== -1) {
                    this.items.splice(index, 1);
                }
                this.$notifications.showSuccess('Se eliminó la reunión.');
            } catch (err) {
                console.log('deleteItem error', err.response);
                this.$notifications.showError('Error al eliminar.');
            } finally {
                this.loadingDelete = false;
            }
            
        },
        formatMeetingDate(meeting) {
            return utils.formatMeetingTime(meeting);
        },
        canDeleteItem (meeting) {
            return new Date(meeting.start) - this.currentTimeByMinute > 0;
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    },
    computed: {
        ...mapState(["meetingsData"]),
        data() {
            return this.meetingsData;
        },
        items() {
            return this.data && this.data.results;
        },
        fetchData() {
            return this.fetchMeetings;
        },
        cleanedFilters() {
            return this.filters
        },
        fromDateFormatted: {
            get: function () {
                return this.filters.from_date && this.formatDate(this.filters.from_date);
            },
            set: function (val) {
                if (!val) {
                    this.filters.from_date = undefined;
                }
            }
        },
        toDateFormatted: {
            get: function () {
                return this.filters.to_date && this.formatDate(this.filters.to_date);
            },
            set: function (val) {
                if (!val) {
                    this.filters.to_date = undefined;
                }
            }
        },
    }
};
</script>
