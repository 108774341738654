var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('Card',{attrs:{"header-color":"cardHeader","closable":"","primary-action-text":"Guardar","primary-action-disabled":!_vm.valid,"secondary-action-text":"Volver","loading":_vm.loading},on:{"close":_vm.close,"primaryAction":_vm.save,"secondaryAction":_vm.close}},[_c('template',{slot:"title"},[_c('span',{staticClass:"button pl-3 text-uppercase white--text",staticStyle:{"font-size":"17px"}},[_vm._v(_vm._s(_vm.title))])]),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(!_vm.loading)?_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"10","sm":"10","centered":""}},[_c('v-text-field',{attrs:{"label":"Título","counter":"","required":"","hide-details":"auto","maxlength":"255"},model:{value:(_vm.eventInstance.title),callback:function ($$v) {_vm.$set(_vm.eventInstance, "title", $$v)},expression:"eventInstance.title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-width":200,"slide-y":"","offset-y":"","max-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.eventInstance.color,"x-small":"","fab":""}},'v-btn',attrs,false),on))]}}],null,false,1457548735),model:{value:(_vm.showColorSelector),callback:function ($$v) {_vm.showColorSelector=$$v},expression:"showColorSelector"}},[_c('v-color-picker',{staticClass:"ma-2",attrs:{"hide-canvas":"","hide-inputs":"","hide-sliders":"","swatches":_vm.swatches,"show-swatches":""},model:{value:(_vm.eventInstance.color),callback:function ($$v) {_vm.$set(_vm.eventInstance, "color", $$v)},expression:"eventInstance.color"}})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10","md":"4"}},[(!_vm.editing)?_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fecha","readonly":"","hide-details":"auto"},model:{value:(_vm.eventDateStart),callback:function ($$v) {_vm.eventDateStart=$$v},expression:"eventDateStart"}},'v-text-field',attrs,false),on))]}}],null,false,1515695782),model:{value:(_vm.showDatePicker),callback:function ($$v) {_vm.showDatePicker=$$v},expression:"showDatePicker"}},[_c('v-date-picker',{attrs:{"locale":_vm.getLocale,"first-day-of-week":"1","allowed-dates":_vm.allowedDates,"min":_vm.minDate,"max":_vm.maxDate,"color":"primary","no-title":""},model:{value:(_vm.eventInstance.date),callback:function ($$v) {_vm.$set(_vm.eventInstance, "date", $$v)},expression:"eventInstance.date"}})],1):_c('v-text-field',{attrs:{"label":"Fecha","readonly":"","hide-details":"auto"},model:{value:(_vm.eventDateStart),callback:function ($$v) {_vm.eventDateStart=$$v},expression:"eventDateStart"}})],1),_c('v-col',{attrs:{"cols":"10","md":"5","lg":"2"}},[_c('v-select',{staticClass:"mt-5",attrs:{"label":"Desde","items":_vm.startTimeOptions,"dense":"","hide-details":"","append-icon":_vm.editing ? '' : '$dropdown',"readonly":_vm.editing},model:{value:(_vm.eventInstance.start),callback:function ($$v) {_vm.$set(_vm.eventInstance, "start", $$v)},expression:"eventInstance.start"}})],1),_c('v-col',{attrs:{"cols":"10","md":"5","lg":"2"}},[_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.endTimeOptions,"label":"Hasta","dense":"","hide-details":"","append-icon":_vm.editing ? '' : '$dropdown',"readonly":_vm.editing},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMinutesFrom(item.value)))])]}}],null,false,4212350765),model:{value:(_vm.eventInstance.end),callback:function ($$v) {_vm.$set(_vm.eventInstance, "end", $$v)},expression:"eventInstance.end"}})],1),(_vm.differentDay)?_c('v-col',{attrs:{"cols":"10","md":"4","lg":"2","align-self":"end"}},[_c('div',{staticClass:"subtitle-1 black--text"},[_vm._v(_vm._s(_vm.eventDateEnd))])]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"10","centered":""}},[_c('v-select',{staticClass:"mt-5",attrs:{"items":_vm.maxParticipantsOptions,"dense":"","hide-details":"","append-icon":_vm.editing ? '' : '$dropdown',"readonly":_vm.editing,"label":"Cantidad máxima de participantes"},model:{value:(_vm.eventInstance.maxParticipants),callback:function ($$v) {_vm.$set(_vm.eventInstance, "maxParticipants", $$v)},expression:"eventInstance.maxParticipants"}})],1),_c('v-col',{attrs:{"cols":"10","centered":""}},[_c('v-text-field',{staticClass:"input-group--focused",attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"label":"Contraseña","counter":"","maxlength":"10","required":"","readonly":_vm.editing,"hide-details":"auto","type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.eventInstance.password),callback:function ($$v) {_vm.$set(_vm.eventInstance, "password", $$v)},expression:"eventInstance.password"}})],1),_c('v-col',{attrs:{"cols":"10","centered":""}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("Descripción")]),_c('v-textarea',{attrs:{"rows":"3","outlined":"","label":"","counter":"","maxlength":"500","auto-grow":"","required":""},model:{value:(_vm.eventInstance.description),callback:function ($$v) {_vm.$set(_vm.eventInstance, "description", $$v)},expression:"eventInstance.description"}})],1)],1)],1)],1):_vm._e()],1)],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }