<template>
    <v-container class="px-4">
        <v-row justify="center">
            <v-col cols="12" sm="3" class="mr-10 mb-10">
                <Card header-color="#f7f7f7" title="Criterios de búsqueda">
                    <!-- <template slot="title">
                        <div class="mx-auto text-centered" style="width: 200px;">
                            <span class="button text-centered" style="font-size: 17px;">Criterios de búsqueda</span>
                        </div>
                    </template> -->
                    <v-form class="pa-5 caption">
                        <label>
                            <b>Usuario UNC:</b>
                        </label>
                        <v-text-field
                            v-model="filters.topic"
                            placeholder="Ingrese el tópico de la reunión"
                            class="pt-0"
                        ></v-text-field>
                        <v-btn
                            tile
                            block
                            :depressed="loading"
                            :disabled="loading"
                            color="primary lighten-1"
                            @click="loading = !loading"
                            class="caption"
                        >
                            <span v-if="!loading">
                                <v-icon small class="mr-2">mdi-magnify</v-icon>Aplicar filtros
                            </span>
                            <span v-else>
                                <v-progress-circular
                                    class="mr-2"
                                    indeterminate
                                    color="cardHeader"
                                    size="15"
                                ></v-progress-circular>Cargando
                            </span>
                        </v-btn>
                    </v-form>
                </Card>
            </v-col>
            <v-col cols="12" sm="7" class="card">
                <Card header-color="cardHeader" action-btn="mdi-plus" dark @actionBtnClicked="addUser">
                    <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Usuarios</span>
                    </template>
                    <GenericTable :headers="headers" :data="usersData" :loading="loading" :loading-delete="loadingDelete" @deleteItem="deleteItem"/>
                </Card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Card from "@/components/Card.vue";
import GenericTable from "@/components/GenericTable.vue";
import { mapActions, mapState } from "vuex";
import utils from '@/utils/utils';
// import { HTTPFront } from '@/services/share';
// import { HTTPFront } from '../../services/share';


export default {
    name: "UserList",
    components: {
        GenericTable,
        Card
    },
    data: () => ({
        filters: {
            topic: null
        },
        loading: true,
        headers: [
            {
                text: "Nombres y Apellidos",
                align: "start",
                sortable: false,
                value: "fullName"
            },
            { text: "Usuario UNC", value: "username" },
            { text: "Estado", value: "status" },
            { text: "", value: "actions", sortable: false, align: "end" }
        ],
        loadingDelete: false,
    }),
    created() {
        setTimeout(() => (this.loading = false), 2000);
        const filters = {};
        // filters.firstNames = "matias";
        this.fetchUsers(filters);
    },
    methods: {
        ...mapActions(["fetchUsers"]),
        addUser() {
            this.$router.push('/usuarios/nuevo');
        },
        async deleteItem(item) {
            console.log('deleteItem', item);
            this.loadingDelete = true;
            try {
                await utils.timeout(500);
                // await utils.delayedError(500);
                // await utils.randomAsync(500);
                this.$notifications.showSuccess('Se eliminó el usuario.');
            } catch (err) {
                this.$notifications.showError('Error al eliminar.');
            } finally {
                this.loadingDelete = false;
            }
            
        },
    },
    computed: {
        ...mapState(["usersData"])
    }
};
</script>
