<template>
    <v-card tile elevation=1>
        <v-toolbar :color="headerColor || '#f7f7f7'" tile elevation=0>
            <v-spacer/>
            <v-toolbar-title class="justify-center text-center body-1">
                {{title || 'Criterios de búsqueda'}}
            </v-toolbar-title>
            <v-spacer/>
        </v-toolbar>
        
        <!-- <v-card :color="headerColor || '#f7f7f7'" tile elevation=0>
            <v-card-title class="justify-center text-center body-1">
                <slot name="title">
                    {{title || 'Criterios de búsqueda'}}
                </slot>
            </v-card-title>
        </v-card> -->

        <v-container fluid px-0>
            <v-row justify="center">
                <v-card-text>
                    <slot />
                </v-card-text>
            </v-row>
            <v-row justify="center" no-gutters class="mb-2">
                <v-col cols="10">
                    <slot name="filterBtn">
                        <v-btn
                            tile
                            block
                            :depressed="loading"
                            :disabled="loading"
                            color="primary"
                            @click="filterBtnClicked"
                            class="caption mt-4"
                        >
                            <span v-if="!loading">
                                <v-icon small class="mb-1">mdi-magnify</v-icon>
                                {{filterBtnText || 'Aplicar filtros'}}
                            </span>
                            <span v-else>
                                <v-progress-circular
                                    class="mb-1"
                                    indeterminate
                                    color="cardHeader"
                                    size="15"
                                ></v-progress-circular>
                                Cargando
                            </span>
                        </v-btn>
                    </slot>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
export default {
    name: "FilterCard",
    props: {
        title: String,
        headerColor: String,
        actionBtn: String,
        dark: Boolean,
        closable: Boolean,
        filterBtnText: String,
        filterBtnDisabled: Boolean,
        loading: Boolean,
        height: Number,
        show: Boolean,
    },
    computed: {
    },
    methods: {
        filterBtnClicked() {
            this.$emit('filterAction');
        },
        close() {
            this.$emit('close');
        }
    },
};
</script>

<style scoped>
    .v-btn.fab-action:hover i {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }

    .v-card__text, .v-card__title {
        word-break: normal;
    }
</style>
