<template>
    <div>
        <v-navigation-drawer
            v-if="validUser"
            v-model="drawer"
            app
            mobile-breakpoint="0"
            clipped
            :width="268"
            class="elevation-10"
            v-click-outside="{
                handler: closeDrawer,
                include: vClickOutsideInclude,
            }"
        >
            <v-list dense>
                <div class="profile-div">
                    <div class="profile-pic"></div>
                    <div class="profile-info">{{currentUser && currentUser.displayName}}</div>
                </div>
                <template v-for="item in menuItems">
                    <v-list-item
                        v-if="!item.subMenu || !item.subMenu.items.length"
                        :key="item.text"
                        link
                        :to="item.route"
                    >
                        <v-list-item-action>
                            <v-icon color="primary">{{ item.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-group v-else :key="item.text" v-model="item.subMenu.active">
                        <template v-slot:activator>
                            <v-list-item-action>
                                <v-icon color="primary">{{ item.icon }}</v-icon>
                            </v-list-item-action>

                            <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </template>
                        <template v-slot:appendIcon>
                            <v-icon small>{{`mdi-${item.subMenu.active ? 'minus' : 'plus'}`}}</v-icon>
                        </template>
                        <v-list-item
                            v-for="subMenuItem in item.subMenu.items"
                            :key="subMenuItem.text"
                            link
                            :to="subMenuItem.route"
                        >
                            <v-list-item-action>
                                <v-icon color="primary">{{subMenuItem.icon}}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="text-caption">{{subMenuItem.text}}</v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </template>

                <v-list-item link>
                    <v-list-item-action>
                        <v-icon color="grey darken-1">mdi-power</v-icon>
                    </v-list-item-action>
                        <v-list-item-title @click.prevent="logout" class="text--darken-1">Cerrar sesión</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <v-app-bar app clipped-left color="primary">
            <v-app-bar-nav-icon v-if="validUser" @click.stop="toggleDrawer" color="white" class="navbar-activator">
                <v-icon v-if="drawer" dark class="active">mdi-arrow-right</v-icon>
            </v-app-bar-nav-icon>
          
                <router-link to="/">
                    <v-img
                        alt="Zewu"
                        class="shrink mt-1 mr-3 hidden-sm-and-down"
                        contain
                        src="@/assets/logo-navbar.png"
                        height="100%"
                        width="60"
                    />
                </router-link>
                <router-link to="/" style="text-decoration: none; color: inherit;">
                    <v-toolbar-title class="mr-12 align-center">
                        <span class="logo-text display-1 white--text">Zewu</span>
                    </v-toolbar-title>
                </router-link>
            <v-spacer></v-spacer>

            <v-menu v-if="validUser" offset-y rounded="0">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="primary" v-bind="attrs" v-on="on">
                        <v-icon color="white">mdi-account-circle</v-icon>
                    </v-btn>
                </template>
                <v-list dense v-if="currentUser">
                    <v-list-item link>
                        <v-list-item-title
                            v-text="`Cerrar sesión`"
                            @click="logout"
                            class="hover-primary"
                        />
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-app-bar>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
    name: "Menu",
    data() {
        return {
            drawer: false,
        };
    },
    methods: {
        logout() {
            this.$emit('logout');
        },
        closeDrawer() {
            this.drawer = false;
        },
        vClickOutsideInclude() {
            return [document.querySelector(".navbar-activator")];
        },
        toggleDrawer() {
            if (this.currentUser) {
                this.drawer = !this.drawer;
            }
        }
    },
    computed: {
        ...mapGetters([
            'currentUser',
            'validUser',
        ]),
        ...mapState([
            'menuItems',
        ]),
    },
    mounted() {
        // <v-img
        //         alt="Observatorio"
        //         class="shrink mt-1 no-margin-top"
        //         contain
        //         src="@/assets/Observatorio_Bosque_Alegre_02.jpg"
        //         width="100%"
        //       />
        //         <div class="profile-info">matias.arganaraz@unc.edu.ar</div>
    }
};
</script>

<style scoped>
.no-margin-top {
    margin-top: 0;
}

.v-navigation-drawer__content .v-list.v-sheet {
    padding-top: 0;
}

.profile-info {
    margin-top: 75px;
    background: rgba(0, 0, 0, 0.37);
    padding: 7px 14px;
    color: #fff;
    position: relative;
    font-size: 13px;
}

.profile-pic {
    padding: 12px;
}

.profile-div {
    display: block;
    margin-bottom: 5px;
    width: 100%;
    background: url(../assets/Observatorio_Bosque_Alegre_02.jpg) no-repeat 0 0;
    background-size: auto;
    background-size: 100%;
}

.active.v-icon {
    /* transform: rotate(-180deg); */
    transform: rotate(180deg) translateY(-1px);
    -webkit-transform: rotate(180deg) translateY(-1px);
}
</style>