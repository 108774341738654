import axios from 'axios';
import store from '../store';

import Vue from 'vue';

export const HTTPApi = axios.create({
    baseURL: '/backend',
    headers: { 'x-client-ajax': 1 },
});

export const publicHTTPApi = axios.create({
    baseURL: '/public-backend',
});

Vue.config.errorHandler = (err, vm, info) => {
    console.log('central errorHandler', { err, vm, info });
    // TODO: Perform any custom logic or log to server
};

const manageExpiredToken = async error => {
    const status = error.response ? error.response.status : null;
    if (status === 418) {
        store.dispatch('handleCannotGetUserError');
    }
    return Promise.reject(error);
};

HTTPApi.interceptors.response.use(response => response, manageExpiredToken);
