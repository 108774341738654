<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="8" sm="8">
                <Card header-color="cardHeader" dark :actions="actions" @edit="edit" @delete="deleteSelf"
                    :loading="loading" closable @close="showList"
                    >
                    <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Reunión</span>
                    </template>
                    <template v-if="!loading">
                        <v-container fluid>
                            <v-row >
                                <v-col cols="8">
                                    <div class="ma-5 mb-10">
                                        <v-row><span class="subtitle-1 font-weight-bold">Título</span></v-row>
                                        <v-row><span class="subtitle-2 font-weight-medium">{{meeting.title}}</span></v-row>
                                    </div>
                                    <div class="ma-5 mb-10">
                                        <v-row><span class="subtitle-1 font-weight-bold">Descripción</span></v-row>
                                        <v-row><span class="subtitle-2 font-weight-medium">{{meeting.description || 'Sin descripción'}}</span></v-row>
                                    </div>
                                    <div class="ma-5 mb-10">
                                        <v-row><span class="subtitle-1 font-weight-bold">Fecha y hora</span></v-row>
                                        <v-row><span class="subtitle-2 font-weight-medium">{{formattedTime}}</span></v-row>
                                    </div>
                                    <div class="ma-5 mb-10" v-if="meeting.password">
                                        <v-row><span class="subtitle-1 font-weight-bold">Contraseña
                                            <v-btn class="ml-2" icon @click="showPassword = !showPassword">
                                                <v-icon v-if="showPassword">mdi-eye</v-icon>
                                                <v-icon v-else>mdi-eye-off</v-icon>
                                            </v-btn>
                                            </span></v-row>
                                        <v-row><span class="subtitle-2 font-weight-medium">{{password}}
                                            </span></v-row>
                                    </div>
                                    <div class="ma-5 mb-10">
                                        <v-row>
                                            <span class="subtitle-1 font-weight-bold">
                                                Enlace para compartir <v-btn class="" icon @click="copyShareLink"><v-icon class="">mdi-content-copy</v-icon></v-btn>
                                            </span>
                                        </v-row>
                                        <v-row>
                                            <span class="subtitle-2 font-weight-medium">
                                                <a target="_blank" :href="meeting.joinUrl">{{meeting.joinUrl}}  </a>
                                            </span>
                                        </v-row>
                                    </div>
                                    <div class="ma-5 mb-10">
                                        <v-row>
                                            <span class="subtitle-1 font-weight-bold">
                                                Enlace para iniciar <v-btn class="" icon @click="copyStartLink"><v-icon class="">mdi-content-copy</v-icon></v-btn>
                                            </span>
                                        </v-row>
                                        <v-row>
                                            <span class="subtitle-2 font-weight-medium" style="max-width: 80%;">
                                                <span>
                                                    <a target="_blank" :href="sharableStartUrl">{{sharableStartUrl}}</a>
                                                </span>
                                                <!-- <span v-else> El enlace estará disponible el {{formattedStartUrlAvailableDate}} </span> -->
                                            </span>
                                        </v-row>
                                    </div>
                                </v-col>
                                <v-col cols="12" md="4" v-if="qr" :class="{ 'my-10': $vuetify.breakpoint.smAndUp}">
                                    <v-img :src="qr"  max-height="300" contain/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                </Card>
            </v-col>
        </v-row>
        <ConfirmDialog v-if="dialogDelete" title="¿Eliminar?" text="No podrás revertir esta operación"
            @cancel="dialogDelete = false" @confirm="deleteItemConfirm" :loading="loadingDelete"/>
    </v-container>
</template>

<script>
import Card from '@/components/Card.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import utils from '@/utils/utils';
import { mapActions } from 'vuex';
import QRCode from 'qrcode';

export default {
    name: 'MeetingView',
    components: {
        Card,
        ConfirmDialog,
    },
    data: () => ({
        showPassword: false,
        loading: true,
        meeting: null,
        dialogDelete: false,
        loadingDelete: false,
        qr: null,
        currentTimeByMinute: new Date(),
    }),
    async created() {
        try {
            setInterval(() => this.currentTimeByMinute = new Date(), 1000);
            this.meeting = await this.fetchMeeting(this.meetingId);
            if (!this.meeting) {
                return this.$router.push({name: 'NotFound'});
            }
            this.loading = false;
            this.qr = await QRCode.toDataURL(this.meeting.joinUrl, { version: 13 });
        } catch (err) {
            console.log('err', err);
        }
    },
    computed: {
        password() {
            return this.showPassword ? this.meeting.password : '* * * * * * * *';
        },
        meetingId() {
            return this.$route.params.id;
        },
        formattedTime() {
            return utils.formatMeetingTime(this.meeting);
        },
        formattedStartUrlAvailableDate() {
            return utils.formatStartTime(new Date(this.meeting.showStartUrlDate));
        },
        isFutureMeeting () {
            return this.meeting && new Date(this.meeting.start) - this.currentTimeByMinute > 0;
        },
        canBeDeleted () {
            return this.isFutureMeeting;
        },
        canBeEdited () {
            return this.isFutureMeeting;
        },
        actions () {
            const actions = [];
            if (this.canBeEdited) {
                actions.push({ event: 'edit', icon: 'mdi-pencil', color: 'green'});
            }
            if (this.canBeDeleted) {
                actions.push({event: 'delete', icon: 'mdi-delete', color: 'red darken-2'});
            }
            return actions;
        },
        sharableStartUrl() {
            return `${location.origin}/#/inicio-de-reunion/${this.meeting.id}/?token=${encodeURIComponent(this.meeting.startUrlToken)}`;
        },

    },
    methods: {
        ...mapActions(['deleteMeeting', 'fetchMeeting']),
        showList() {
            this.$router.push('/reuniones/lista');
        },
        edit() {
            this.$router.push(`/reuniones/${this.meetingId}/editar`);
        },
        deleteSelf() {
            if (!this.canBeDeleted) {
                return this.$$notifications.showError('No se puede borrar después de la hora de inicio.')
            }
            this.dialogDelete = true;
        },
        async deleteItemConfirm () {
            this.loadingDelete = true;
            try {
                await this.deleteMeeting(this.meetingId);
                this.$notifications.showSuccess('Se eliminó la reunión.');
            } catch (err) {
                console.log('deleteItem error', err.response);
                this.$notifications.showError('Error al eliminar.');
            } finally {
                this.loadingDelete = false;
                this.dialogDelete = false;
                this.showList();
            }
        },
        copyShareLink() {
            utils.copyToClipboard(this.meeting.joinUrl);
            this.$notifications.showInfo('Enlace para compartir copiado.');
        },
        copyStartLink() {
            utils.copyToClipboard(this.sharableStartUrl);
            this.$notifications.showInfo('Enlace para iniciar copiado.');
        },
    },
};
</script>
