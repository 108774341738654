<template>
    <div :class="`myIcon myIcon-${type}`">
        <div class="myIconContent">
            <slot>
                {{ symbol }}
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: ['type'],
    name: 'ConfirmDialogSymbol',
    computed: {
        symbol() {
            switch(this.type) {
                case 'error':
                    return '!';
                case 'question':
                    return '?';
                case 'warning':
                default:
                    return '!';
            }
        }
    }
};
</script>

<style scoped>

.myIcon .myIconContent {
    display: flex;
    align-items: center;
    font-size: 2.1em;
}
.myIcon {
    position:relative;
    box-sizing:content-box;
    justify-content:center;
    width:2.75em;
    height:2.75em;
    margin:1.25em auto 0;
    border:.15em solid transparent;
    border-radius: 50% !important;
    font-family:inherit;
    line-height:5em;
    cursor:default;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    display: flex;
}

.myIcon.myIcon-warning {
    border-color: #facea8;
    color: #f8bb86;
}

.myIcon.myIcon-error {
    border-color: red;
    color: red;
}

.myIcon.myIcon-question {
    border-color: orange;
    color: orange;
}

.myIcon.myIcon-info {
    border-color: green;
    color: green;
}



</style>