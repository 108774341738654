const moment = require('moment');

class Utils {
    get months () {
        return ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];
    }

    get days () {
        return ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    }

    delayedError (ms) {
        return new Promise((resolve, reject) => setTimeout(reject, ms));
    }

    randomAsync (ms) {
        return new Promise((resolve, reject) => {
            setTimeout(Math.random() > 0.5 ? resolve : reject, ms);
        });
    }

    timeout (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    queryParams (filters, validEntries = ([k, v]) => ![undefined, '', '__all__'].includes(v)) {
        return Object.entries(filters).filter(validEntries).map(([k, v]) => `${k}=${v}`).join('&');
    }

    setNestedProp (obj, propPath, value) {
        if (typeof obj !== 'object') {
            throw new Error('The first parameter must be an object.');
        }
        propPath = propPath.split('.');
        const last = propPath.pop();
        const o = propPath.reduce((acc, k) => { acc[k] = {}; return acc[k]; }, obj);
        o[last] = value;
    }

    getTimezoneOffset () {
        return new Date().getTimezoneOffset();
    }

    formatDate1 (date, utc = true) {
        utc = utc ? 'UTC' : '';
        return `${date[`get${utc}FullYear`]()}-${this.padNumber(date[`get${utc}Month`]() + 1)}-${this.padNumber(date[`get${utc}Date`]())}`;
    }

    isValidDate (date) {
        return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    }

    formatDate (date, format = 'MM/DD/YYYY', utc = false) {
        if (!this.isValidDate(date) && !moment.isMoment(date)) {
            throw new Error('The date parameter must be a date or moment instance');
        }
        return moment(date).format(format);
    }

    localeFormatDate (date) {
        return `${this.padNumber(date.getDate())}/${this.padNumber(date.getMonth() + 1)}/${date.getFullYear()}`;
    }

    localeFormatTime (date) {
        const hour = date.getHours();
        const minutes = date.getMinutes();
        return `${this.padNumber(hour % 12 || 12)}:${this.padNumber(minutes)}${hour < 12 ? 'a' : 'p'}m`;
    }

    utcDateFromMillis (millis) {
        return new Date(millis - this.getTimezoneOffset() * 60 * 1000);
    }

    formatEvent (event) {
        const start = this.utcDateFromMillis(event.start);
        const end = this.utcDateFromMillis(event.end);
        const date = start;

        const dayName = this.days[start.getUTCDay()];
        const monthName = this.months[start.getUTCMonth()];
        const timeFrom = `${this.padNumber(start.getUTCHours())}:${this.padNumber(start.getUTCMinutes())}`;
        const timeTo = `${this.padNumber(end.getUTCHours())}:${this.padNumber(end.getUTCMinutes())}`;
        let response = `${dayName}, ${date.getUTCDate()} de ${monthName}. ${timeFrom} - ${timeTo}`;
        if (!this.sameDay(start, end)) {
            response += ` ${this.days[end.getUTCDay()]}`;
        }
        return response;
    }

    formatEventDate (date) {
        const dayName = this.days[date.getDay()];
        const monthName = this.months[date.getMonth()];
        const day = date.getDate();
        return `${dayName}, ${day} de ${monthName}`;
    }

    formatEventTime (event) {
        const start = this.utcDateFromMillis(event.start);
        const end = this.utcDateFromMillis(event.end);
        const timeFrom = `${this.padNumber(start.getUTCHours())}:${this.padNumber(start.getUTCMinutes())}`;
        const timeTo = `${this.padNumber(end.getUTCHours())}:${this.padNumber(end.getUTCMinutes())}`;
        let response = `${timeFrom} - ${timeTo}`;
        if (!this.sameDay(start, end)) {
            response += 'Distinto día';
        }
        return response;
    }

    formatMeetingTime (meeting) {
        const startDate = new Date(meeting.start);
        const endDate = new Date(meeting.end);
        let formatted = `${this.localeFormatDate(startDate)} de ${this.localeFormatTime(startDate)} a ${this.localeFormatTime(endDate)}`;
        return formatted;
    }

    formatStartTime (date) {
        return `${this.padNumber(date.getDate())}/${this.padNumber(date.getMonth() + 1)}/${date.getFullYear()} a ${this.pluralize('la', date.getHours())} ${this.padNumber(date.getHours())}:${this.padNumber(date.getMinutes())} h`;
    }

    pluralize (s, count, plural = 's', absolute = false) {
        return count !== 1
            ? (absolute ? plural : s + plural)
            : s;
    }

    padNumber (number, spaces = 2) {
        return number.toString().padStart(spaces, '0');
    }

    capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    sameDay (d1, d2) {
        return d1.getDate() === d2.getDate() &&
            d1.getMonth() === d2.getMonth() &&
            d1.getFullYear() === d2.getFullYear();
    }

    isFutureDate (date) {
        return date - new Date() > 0;
    }

    rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a;
    }

    rndElement (arr) {
        return arr[this.rnd(0, arr.length - 1)];
    }

    getLocale () {
        return navigator.language || navigator.browserLanguage || (navigator.languages || ['es-AR'])[0];
    }

    copyToClipboard (textToCopy) {
        const tempInput = document.createElement('input');
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
    }
}

export default new Utils();
