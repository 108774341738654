<template>
  <v-app class="app-container" :style="cssProps">
    <NavbarMenu v-if="!forceLogin" @logout="logout"/>
    <FullScreenError v-if="fullScreenError.active" @confirm="fullScreenError.handler" :error="fullScreenError"/>

    <v-main v-else class="pl-0 main-content" >
        <v-container v-if="forceLogin" fill-height fluid pa-0>
            <router-view/>
        </v-container>
        <v-container v-else-if="isLoading" fill-height>
            <v-row class="mt-15" justify="center">
                <v-progress-circular centered class="mr-2" indeterminate color="cardHeader" size=25></v-progress-circular>
            </v-row>
        </v-container>
        <v-container v-else fluid class="mx-0">
            <router-view />
        </v-container>
        
    </v-main>

    <v-footer v-if="!forceLogin" app inset height="140">
      <v-img src="./assets/unc-pi-logo.png" alt max-height="60px" contain class="img-logo"/>
    </v-footer>
    <Notification v-for="notification in notifications" :key="notification.id" :notification="notification" multi-line/>
  </v-app>
</template>

<script>

import FullScreenError from "@/components/FullScreenError.vue";
import Notification from '@/components/Notification.vue';
import NavbarMenu from "@/components/NavbarMenu.vue";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    NavbarMenu,
    Notification,
    FullScreenError,
  },
  data: () => ({
    snackbar: true,
  }),
  created() {
    this.initStatus();
  },
  computed: {
        ...mapState([
            'fullScreenError',
            'isLoading',
            'isInitStatus',
            'authService',
        ]),
        ...mapGetters([
            'forceLogin',
        ]),
        cssProps() {
            const themeColors = {};
            Object.keys(this.$vuetify.theme.themes.light).forEach(color => {
                themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color];
            });
            return themeColors;
        },
        notifications() {
            return this.$notifications.notifications.map((e, i) => ({...e, i}));
        },
    },
    methods: {
        ...mapActions([
            'initStatus',
            'logout',
        ]),
        ...mapMutations([
            'showNotification',
            'dismissNotification',
        ])
    }
};
</script>

<style scoped>
footer {
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: auto;
  color: #a2a2a2;
  padding-top: 25px;
  padding-bottom: 40px;
  display: inline-block;
  left: 0 !important;
}
</style>

<style>
.app-container,
footer {
  background: var(--v-background) !important;
}

.app-container {
  min-width: 360px;
  min-height: 360px;
}

html {
  overflow-y: auto;
}

.img-logo {
    -webkit-filter: invert(30%);
    filter: invert(30%);
}

@import url('./assets/fonts/Reforma/Reforma Webfonts/fonts.css');

.logo-text {
    font-weight: 900 !important;
    font-family: Reforma2018 !important;
}
</style>
