import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconsfont: 'mdiSvg',
    },
    theme: {
        themes: {
            light: {
                primary: '#2d8cff',
                secondary: '#0000ff',
                accent: '#ff0000',
                error: '#ff0000',
                background: '#ececec',
                cardHeader: '#9e9e9e',
                anchor: '#0077cc',
                red: '#ff0000'
            }
        },
        options: { customProperties: true },
    }
});
