import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import authService from '../auth/authService';
import { HTTPApi, publicHTTPApi } from '../services/share';
import utils from '@/utils/utils';

Vue.use(Vuex);

const defaultLimit = 10;

const emptyItemsData = {
    results: [],
    loading: true,
    page: 1,
    limit: defaultLimit,
    totalPages: null,
    totalResults: null,
};

const defaultFullScreenError = {
    handler: () => false,
    active: false,
    showMessage: true,
    title: '',
    text: '',
    btnText: '',
};

const state = {
    isLoading: true,
    isInitStatus: false,
    fullScreenError: { ...defaultFullScreenError },
    authService: null,
    menuItems: [],
    usersData: { ...emptyItemsData },
    accountsData: { ...emptyItemsData },
    meetingsData: { ...emptyItemsData },
};

const getters = {
    getLocale: () => utils.getLocale(),
    currentUser: state => state.authService?.user,
    isAuthenticated: state => state.authService?.isAuthenticated,
    validUser: state => state.authService?.validUser,
    forceLogin: state => state.authService?.forceLogin,
    showNavbar: state => state.authService?.ready && !state.authService?.forceLogin,
};

const mutations = {
    initStatus (state) {
        state.authService = authService;
        state.isInitStatus = true;
        state.isLoading = false;
    },
    setLoading (state, value) {
        state.isLoading = value;
    },
    clearUser (state) {
        state.isInitStatus = false;
        state.currentUser = null;
    },
    logout (state) {
        state.isInitStatus = false;
        state.currentUser = null;
        window.location.href = '/logout';
    },
    login (state) {
        state.isInitStatus = false;
        state.currentUser = null;
    },
    clearFullScreenError (state) {
        state.fullScreenError = { ...defaultFullScreenError };
    },
    setFullScreenError (state, { handler, title, text, btnText, component }) {
        const actualHandler = () => {
            state.fullScreenError.active = false;
            handler();
        };
        state.fullScreenError = { ...defaultFullScreenError, handler: actualHandler, title, text, btnText, component, active: true };
    },
    showFullScreenError (state) {
        state.fullScreenError.active = true;
    },
    setMenuItems (state, menuItems) {
        state.menuItems = menuItems.map(e => ({ ...e, ...(e.subMenu && { subMenu: { ...e.subMenu, active: false } }) }));
    },
    setUsersData (state, usersData) {
        state.usersData = usersData;
    },
    clearData (state, name) {
        state[name] = { ...emptyItemsData };
    },
    setAccountsData (state, accountsData) {
        state.accountsData = accountsData;
    },
    setMeetingsData (state, meetingsData) {
        state.meetingsData = meetingsData;
    }
};

const actions = {
    async initStatus ({ commit, dispatch }) {
        const fn = async () => {
            if (authService.validUser) {
                await dispatch('loadMenu');
            }
            commit('initStatus');
        };
        if (authService.ready) {
            fn();
        }

        authService.$watch('ready', async ready => {
            if (ready) {
                fn();
            }
        });
    },
    async loadMenu ({ commit }) {
        try {
            const menuItems = (await HTTPApi.get('/menus')).data;
            commit('setMenuItems', menuItems);
        } catch (err) {
            console.log('loadMenu error', err);
        }
    },
    async logout ({ commit }) {
        commit('logout');
    },
    async login ({ commit }, target) {
        commit('login');
        let href = '/login';
        if (target) {
            href += `?t=${target}`;
        }
        window.location.href = href;
    },
    async handleCannotGetUserError ({ commit, dispatch }) {
        await dispatch('setLoading', true);
        commit('setFullScreenError', {
            handler: () => dispatch('login'),
            title: 'La sesión del usuario ha caducado',
            text: 'Se ha cerrado la sesión.',
            btnText: 'Iniciar sesión',
        });
    },
    async fetchUsers ({ commit }, filters) {
        try {
            commit('clearData', 'usersData');
            const queryParams = utils.queryParams(filters);
            let usersData = (await HTTPApi.get(`/users?${queryParams}`)).data;
            usersData.results.forEach(e => { e.fullName = `${e.firstNames} ${e.lastNames}`; });
            commit('setUsersData', usersData);
            return usersData;
        } catch (error) {
            console.log('getUsers error', error);
        }
    },
    saveUser (store, instance) {
        return HTTPApi.post('/users', instance);
    },
    async fetchPersonByUsername (store, username) {
        try {
            const data = (await HTTPApi.get(`/yuli/v2/people/?user=${username}`)).data;
            return data.length ? data[0] : null;
        } catch (error) {
            console.log('fetchPersonByUsername error', error);
        }
    },
    async fetchAccounts ({ commit }, filters = {}) {
        try {
            commit('clearData', 'accountsData');
            await utils.timeout(1000);
            const queryParams = utils.queryParams(filters);
            let data = (await HTTPApi.get(`/meetingsProviderAccount?${queryParams}`)).data;
            commit('setAccountsData', data);
        } catch (error) {
            console.log('getAccounts error', error);
        }
    },
    saveAccount (store, instance) {
        return HTTPApi.post('/meetingsProviderAccount', instance);
    },
    deleteAccount (store, id) {
        return HTTPApi.delete(`/meetingsProviderAccount/${id}`);
    },
    async fetchMeetings ({ commit }, filters = {}) {
        try {
            commit('clearData', 'meetingsData');
            await utils.timeout(1000);
            const queryParams = utils.queryParams(filters);
            let data = (await HTTPApi.get('/meetings?' + queryParams)).data;
            commit('setMeetingsData', data);
        } catch (error) {
            console.log('getMeetings error', error);
        }
    },
    async fetchMeeting (store, id) {
        try {
            return (await HTTPApi.get(`/meetings/${id}`)).data;
        } catch (err) {
            console.log('getMeeting error', err);
            return null;
        }
    },
    async fetchMeetingStartUrl (store, { meetingId, token }) {
        return publicHTTPApi.get(`/meetings/${meetingId}/start-url?token=${token}`);
    },
    saveMeeting (store, instance) {
        instance = { ...instance };
        let url = '/meetings/';
        let method = 'post';

        if (instance.id) {
            url += instance.id;
            method = 'patch';
            delete instance.id;
        }
        return HTTPApi[method](url, instance);
    },
    deleteMeeting (store, id) {
        return HTTPApi.delete(`/meetings/${id}`);
    },
};

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {
    }
});
