<template>
    <v-container fluid>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <Card header-color="cardHeader" closable @close="close"
                    primary-action-text="Guardar"
                    :primary-action-disabled="!valid"
                    secondary-action-text="Volver"
                    @primaryAction="save"
                    @secondaryAction="close"
                    :loading="loading"
                    >
                    <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">{{title}}</span>
                    </template>
                    <v-container fluid>
                        <v-row>
                            <v-card-text v-if="!loading">
                                <v-container fluid>
                                    <v-row justify="space-between">
                                        <!-- TITULO -->
                                        <v-col
                                            cols="10"
                                            sm="10"
                                            centered
                                            >
                                                <v-text-field
                                                    label="Título"
                                                    counter
                                                    v-model="eventInstance.title"
                                                    required
                                                    hide-details="auto"
                                                    maxlength="255"
                                                ></v-text-field>
                                        </v-col>
                                        <!-- COLOR -->
                                        <v-col cols="12" sm="2">
                                            <v-menu
                                                v-model="showColorSelector"
                                                :close-on-content-click="true"
                                                :nudge-width="200"
                                                slide-y
                                                offset-y
                                                max-width="150"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        :color="eventInstance.color"
                                                        x-small
                                                        fab
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-btn>
                                                </template>
                                                <v-color-picker
                                                    class="ma-2"
                                                    hide-canvas
                                                    hide-inputs
                                                    hide-sliders
                                                    :swatches="swatches"
                                                    show-swatches
                                                    v-model="eventInstance.color"
                                                ></v-color-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row >
                                    <!-- <v-row class="pb-0 mb-0">
                                        <v-col cols=12 class="pb-0 mb-0">
                                        </v-col>
                                    </v-row> -->
                                    <v-row>
                                        <!-- FECHA INICIO -->
                                        <v-col cols="10" md="4">
                                            <v-menu
                                                v-if="!editing"
                                                v-model="showDatePicker"
                                                :close-on-content-click="true"
                                                transition="scale-transition"
                                                offset-y
                                                max-width="290px"
                                                min-width="auto"
                                                >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="eventDateStart"
                                                        label="Fecha"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        hide-details="auto"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="eventInstance.date"
                                                    :locale="getLocale"
                                                    first-day-of-week="1"
                                                    :allowed-dates="allowedDates"
                                                    :min="minDate"
                                                    :max="maxDate"
                                                    color="primary"
                                                    no-title
                                                ></v-date-picker>
                                            </v-menu>
                                            <v-text-field
                                                v-else
                                                v-model="eventDateStart"
                                                label="Fecha"
                                                readonly
                                                hide-details="auto"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="10" md="5" lg="2">
                                            <v-select
                                                class="mt-5"
                                                label="Desde"
                                                v-model="eventInstance.start"
                                                :items="startTimeOptions"
                                                dense
                                                hide-details
                                                :append-icon="editing ? '' : '$dropdown'"
                                                :readonly="editing"
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="10" md="5" lg="2">
                                            <v-select
                                                class="mt-5"
                                                v-model="eventInstance.end"
                                                :items="endTimeOptions"
                                                label="Hasta"
                                                dense
                                                hide-details
                                                :append-icon="editing ? '' : '$dropdown'"
                                                :readonly="editing"
                                            >
                                            <template v-slot:selection="{ item }">
                                                    <span>{{ formatMinutesFrom(item.value) }}</span>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="10" md="4" lg="2" v-if="differentDay" align-self="end">
                                            <div class="subtitle-1 black--text">{{eventDateEnd}}</div>
                                            <!-- <v-text-field
                                                v-model="eventDateEnd"
                                                label=""
                                                readonly
                                                hide-details="auto"
                                            ></v-text-field> -->
                                        </v-col>
                                    </v-row>
                                        <v-row>
                                            <!-- MAX CANT PARTICIPANTES -->
                                            <v-col
                                                    cols="10"
                                                    centered
                                                >
                                                <v-select
                                                    class="mt-5"
                                                    v-model="eventInstance.maxParticipants"
                                                    :items="maxParticipantsOptions"
                                                    dense
                                                    hide-details
                                                    :append-icon="editing ? '' : '$dropdown'"
                                                    :readonly="editing"
                                                    label="Cantidad máxima de participantes"
                                                ></v-select>
                                            </v-col>
                                            <!-- CONTRASEÑA -->
                                            <v-col
                                                    cols="10"
                                                    centered
                                                >
                                                    <v-text-field
                                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                    label="Contraseña"
                                                    v-model="eventInstance.password"
                                                    counter
                                                    maxlength="10"
                                                    required
                                                    :readonly="editing"
                                                    hide-details="auto"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    @click:append="showPassword = !showPassword"
                                                    class="input-group--focused"
                                                    ></v-text-field>
                                            </v-col>
                                            <!-- DESCRIPCIÓN -->
                                            <v-col
                                                cols="10"
                                                centered
                                                >
                                                <span style="font-size: 12px;">Descripción</span>
                                                    <v-textarea
                                                    rows="3"
                                                    outlined
                                                    label=""
                                                    v-model="eventInstance.description"
                                                    counter
                                                    maxlength="500"
                                                    auto-grow
                                                    required
                                                    ></v-textarea>
                                                </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                        </v-row>
                    </v-container>
                </Card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Card from "@/components/Card.vue";
import { mapActions, mapGetters } from "vuex";
import utils from '@/utils/utils';
import moment, { min } from 'moment';

export default {
    name: 'NewMeeting2',
    components: {
        Card
    },
    data: () => ({
        defaultTitle: 'Sin Título',
        showDatePicker: false,
        showColorSelector: false,
        colors: ['#E67C73', '#F4511E', '#F6BF26', '#0B8043', '#039BE5', '#8E24AA'],
        
        showPassword: false,
        emptyEvent: {
            start: null,
            end: null,
            title: '',
            maxParticipants: 300,
            password: null,
            date: '2021-06-07',
        },
        eventInstance: null,
        topicRules: [
            v => !!v || 'El título es requerido',
        ],
        valid: true,
        loadingData: true,
        loadingSave: false,
        currentTimeByMinute: new Date(),
    }),
    async created() {
        this.setEventInstance();
        setInterval(() => this.currentTimeByMinute = new Date(), 1000);
    },
    methods: {
        ...mapActions(['saveMeeting', 'fetchMeeting']),
        getRandomColor() {
            return utils.rndElement (this.colors);
        },
        async save() {
            try {
                let toSave = { ...this.eventInstance };
                let startDate = moment(toSave.date, 'YYYY-MM-DD');
                toSave.start = moment(startDate).add(toSave.start, 'minutes').toISOString();
                toSave.end = moment(startDate).add(toSave.end, 'minutes').toISOString();
                toSave.title = toSave.title || this.defaultTitle;
                delete toSave.date;
                this.loadingSave = true;
                if (this.editing) {
                    toSave = {
                        id: toSave.id,
                        description: toSave.description,
                        title: toSave.title,
                    }
                }
                const saved = (await this.saveMeeting(toSave)).data;
                this.$notifications.showSuccess('Reunión guardada');
                this.$router.push(`/reuniones/${saved.id}`);
            } catch (err) {
                console.log('err', err);
                let message = 'Error al guardar.';
                if (err.response?.status === 422 && err.response?.data?.message === 'No account available.') {
                    message = 'El horario seleccionado no está disponible.';
                }
                this.$notifications.showError(message);
            } finally {
                this.loadingSave = false;
            }
        },
        allowedDates(date) {
            // TODO
            return true;
        },
        formatDate (date) {
            if (!date) {
                return null;
            }
            return moment(date, 'YYYY-MM-DD').toLocaleString();
        },
        close () {
            this.editing ? this.showMeeting() : this.showList();
        },
        showList () {
            this.$router.push('/reuniones/lista');
        },
        showMeeting () {
            this.$router.push(`/reuniones/${this.instanceId}`);
        },
        validate () {
            return this.$refs.form.validate();
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation();
        },
        requiredStringRules(text) {
            return [v => !!v || text];
        },
        async setEventInstance() {
            if (!this.editing) {
                return this.clearEventInstance();
            }
            try {
                let instance =  await this.fetchMeeting(this.instanceId);
                if (!instance) {
                    return this.$router.push({name: 'NotFound'});
                }
                let start = new Date(instance.start);
                let end = new Date(instance.end);
                this.eventInstance = {
                    ...instance,
                    date: utils.formatDate(start, 'YYYY-MM-DD'),
                    start: start.getHours() * 60 + start.getMinutes(),
                    end: end.getHours() * 60 + end.getMinutes(),
                    description: instance.description ?? '',
                }
                this.loadingData = false;
            } catch (err) {
                console.log('loading meeting error', err);
            }
        },
        clearEventInstance() {
            this.eventInstance = {
                ...this.emptyEvent,
                color: this.getRandomColor(),
                date: utils.formatDate(new Date(), 'YYYY-MM-DD'),
            };
            this.eventInstance.start = this.startTimeOptions[0].value;

            this.eventInstance.end = this.endTimeOptions.length < 4 ? this.endTimeOptions[this.endTimeOptions.length - 1].value : this.endTimeOptions[3].value;
            this.loadingData = false;
        },
        formatMinutesFrom(minutes) {
            return this.formatMinutes(minutes);
        },
        formatMinutesTo(minutes) {
            const diff = minutes - this.start;
            const diffText = diff >= 60 ? `${(diff/60).toString().replace('.', ',')} h` : `${diff} min`;
            return this.formatMinutes(minutes) +  ` (${diffText})`
        },
        formatMinutes(minutes) {
            const hour = Math.floor(minutes / 60) % 24;
            const minutesLeft = minutes % 60;
            return `${utils.padNumber(hour % 12 || 12)}:${utils.padNumber(minutesLeft)}${hour < 12 ? 'a' : 'p'}m`;
        },
    },
    computed: {
        loading() { 
            return this.loadingData || this.loadingSave;
        },
        title() {
            return this.editing ? 'Editar reunión' : 'Nueva reunión'
        },
        duration () {
            return this.end - this.start;
        },
        eventDateStart() {
            return utils.formatEventDate(moment(this.eventInstance.date, 'YYYY-MM-DD').toDate());
        },
        eventDateEnd() {
            const hour = Math.floor(this.start / 60);
            const minute = this.start % 60;

            const startMoment = moment(this.eventInstance.date, 'YYYY-MM-DD').set({hour, minute});
            const endMoment = moment(startMoment).add(this.duration, 'minutes');
            return utils.formatEventDate(endMoment.toDate());
        },
        isToday() {
            return this.eventInstance?.date && utils.sameDay(new Date(), moment(this.eventInstance.date, 'YYYY-MM-DD').toDate());
        },
        swatches() {
            return this.colors.map(c => [c]);
        },
         ...mapGetters(['currentUser', 'getLocale']),
        toDateFormatted: {
            get: function () {
                return this.eventInstance.date && this.formatDate(this.eventInstance.date);
            },
            set: function (val) {
                if (!val) {
                    this.eventInstance.date = undefined;
                }
            }
        },
        formattedTime() {
            return utils.formatMeetingTime(this.meeting);
        },
        maxParticipantsOptions() {
            return [300, 500].filter(e => e <= this.currentUser.systemUser.maxMeetingParticipants);
        },
        minutesBetweenTimes() {
            return 15;
        },
        fullDateTimeOptions() {
            const minutesBetweenTimes = this.minutesBetweenTimes;
            return Array.from({length: 24 * 60 / minutesBetweenTimes}, (_, i) => minutesBetweenTimes * i);
        },
        startTimeRawOptions() {
            let now = this.currentTimeByMinute;
            let from = this.isToday
                ? now.getHours() * 60 + now.getMinutes() - now.getMinutes() % this.minutesBetweenTimes
                : 0;
            return this.fullDateTimeOptions.filter(e => e >= from);
        },
        startTimeOptions() {
            return this.startTimeRawOptions.map(e => ({ text: this.formatMinutesFrom(e), value: e}));
        },
        maxDurationMinutes() {
            return 60 * 12;
        },
        endtimeRawOptions() {
            const startTime = this.eventInstance.start;
            const tomorrowOptions = this.fullDateTimeOptions.map(e => e + 24 * 60);
            const maxEndTime = startTime + this.maxDurationMinutes;
            return [...this.startTimeRawOptions, ...tomorrowOptions].filter( e => e > startTime && e <= maxEndTime);
        },
        endTimeOptions() {
            return this.endtimeRawOptions.map(e => ({ text: this.formatMinutesTo(e), value: e}));
        },
        minDate() {
            return new Date().toISOString();
        },
        maxDate() {
            const monthsAhead = 3;
            const end = moment().add(monthsAhead, 'months').toDate();
            end.setUTCHours(0, 0, 0, 0);
            return end.toISOString();
        },
        start() {
            return this.eventInstance?.start;
        },
        end() {
            return this.eventInstance?.end;
        },
        differentDay() {
            return this.end >= 24 * 60;
        },
        instanceId() {
            return this.$route.params.id;
        },
        editing() {
            return !!this.instanceId;
        }
    },
    watch: {
        start(newVal) {
            if (!this.end || newVal >= this.end || this.end - newVal > this.maxDurationMinutes) {
                this.eventInstance.end = newVal + 60;
            }
        },
        startTimeRawOptions(newVal) {
            if (!this.start || !newVal.includes(this.start)) {
                this.eventInstance.start = newVal[0];
            }
        }
    },
};
</script>
