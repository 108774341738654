<template>
    <v-container class="px-4">
        <v-row justify="center">
            <v-col cols="12" sm="6">
                <Card header-color="cardHeader" closable @close="showList"
                    primary-action-text="Guardar"
                    :primary-action-disabled="!valid"
                    secondary-action-text="Volver"
                    @primaryAction="save"
                    @secondaryAction="showList"
                    :loading="loading"
                    >
                    <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Agregar cuenta</span>
                    </template>
                    <v-form
                        class="pa-5 caption"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit="save"
                    >
                        <v-text-field
                        v-model="model.email"
                        :rules="emailRules"
                        label="Correo electrónico"
                        required
                        
                        ></v-text-field>
                        <v-select
                            class="mt-5"
                            v-model="model.maxMeetingParticipants"
                            :items="maxParticipantsOptions"
                            dense
                            hide-details
                            label="Cantidad máxima de participantes"
                        ></v-select>
                        <v-checkbox
                        v-model="model.externallyActive"
                        label="Vigente"
                        
                        ></v-checkbox>

                        <v-checkbox
                        v-model="model.enabled"
                        label="Habilitada"
                        
                        ></v-checkbox>

                        <!-- <v-btn
                        :disabled="!valid"
                        color="success"
                        class="mr-4"
                        @click="validate"
                        >
                        Validate
                        </v-btn>

                        <v-btn
                        color="error"
                        class="mr-4"
                        @click="reset"
                        >
                        Reset Form
                        </v-btn>

                        <v-btn
                        color="warning"
                        @click="resetValidation"
                        >
                        Reset Validation
                        </v-btn> -->
                    </v-form>
                        <!-- <v-btn
                            tile
                            block
                            :depressed="loading"
                            :disabled="loading"
                            color="primary lighten-1"
                            @click="loading = !loading"
                            class="caption"
                        >
                            <span v-if="!loading">
                                <v-icon small class="mr-2">mdi-magnify</v-icon>Aplicar filtros
                            </span>
                            <span v-else>
                                <v-progress-circular
                                    class="mr-2"
                                    indeterminate
                                    color="cardHeader"
                                    size="15"
                                ></v-progress-circular>Cargando
                            </span>
                        </v-btn> -->
                </Card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Card from "@/components/Card.vue";
import { mapActions } from "vuex";
import utils from '@/utils/utils';

export default {
    name: 'NewAccount',
    components: {
        Card
    },
    data: () => ({
        maxParticipantsOptions: [300, 500],
        model: {
            email: null,
            externallyActive: true,
            enabled: true,
            maxMeetingParticipants: 300,
        },
        valid: true,
        loading: false,
        emailRules: [
            v => !!v || 'El correo electrónico es requerido',
            v => /.+@.+\..+/.test(v) || 'Correo electrónico no válido',
        ],
    }),
    methods: {
        ...mapActions(["saveAccount"]),
        showList() {
            this.$router.push('/configuracion/cuentas');
        },
        async save() {
            if (!this.validate()) {
                return false;
            }
            console.log('save account', this.model);
            this.loading = true;
            try {
                // await utils.delayedError(1500);
                await this.saveAccount(this.model);
                this.$notifications.showSuccess('Cuenta guardada.');
                return this.showList();
            } catch (err) {
                console.log('error', err.response);
                let message = 'Error al guardar.';
                const email = this.model.email;
                if (err.response?.status) {
                    const status = err.response.status
                    if (status === 400 && err.response.data.message.includes('message":"email must be unique"')) {
                        message = `Ya existe la cuenta "${email}".`;
                    } else if (status === 422 && err.response.data.message === 'User does not exist') {
                        message = `No se encuentra la cuenta "${email}" en Zoom.`;
                    }

                } 
                this.$notifications.showError(message);
            } finally {
                this.loading = false;
            }
            

            // this.loading = false;
        },
        validate () {
            return this.$refs.form.validate();
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        requiredStringRules(text) { 
            return [v => !!v || text];
        },
    },
};
</script>
