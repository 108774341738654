var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('FilterCard',{attrs:{"loading":_vm.loading},on:{"filterAction":_vm.fetch}},[_c('v-form',{staticClass:"pa-5 caption",on:{"submit":_vm.fetch}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"label":"Título"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetch($event)}},model:{value:(_vm.filters.title),callback:function ($$v) {_vm.$set(_vm.filters, "title", $$v)},expression:"filters.title"}}),_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Fecha desde","readonly":""},model:{value:(_vm.fromDateFormatted),callback:function ($$v) {_vm.fromDateFormatted=$$v},expression:"fromDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showFromDatePicker),callback:function ($$v) {_vm.showFromDatePicker=$$v},expression:"showFromDatePicker"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":""},model:{value:(_vm.filters.from_date),callback:function ($$v) {_vm.$set(_vm.filters, "from_date", $$v)},expression:"filters.from_date"}})],1),_c('v-menu',{attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":"","label":"Fecha hasta","readonly":""},model:{value:(_vm.toDateFormatted),callback:function ($$v) {_vm.toDateFormatted=$$v},expression:"toDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showtoDatePicker),callback:function ($$v) {_vm.showtoDatePicker=$$v},expression:"showtoDatePicker"}},[_c('v-date-picker',{attrs:{"color":"primary","no-title":""},model:{value:(_vm.filters.to_date),callback:function ($$v) {_vm.$set(_vm.filters, "to_date", $$v)},expression:"filters.to_date"}})],1)],1)],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"caption",attrs:{"to":"nueva","tile":"","block":"","color":"primary"}},[(!_vm.loading)?_c('span',[_c('v-icon',{staticClass:"mb-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v(" Crear reunión ")],1):_vm._e()])],1)],1)],1),_c('v-col',{class:{ 'pl-4': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"cols":"12","md":"7"}},[_c('Card',{attrs:{"header-color":"cardHeader","dark":"","action-btn":"mdi-calendar","title":"reuniones"},on:{"actionBtnClicked":_vm.add}},[_c('GenericTable',{attrs:{"headers":_vm.headers,"data":_vm.data,"loading":_vm.loading,"loading-delete":_vm.loadingDelete,"canDeleteItem":_vm.canDeleteItem},on:{"delete":_vm.deleteItem,"view":_vm.viewItem},scopedSlots:_vm._u([{key:"item.datetime",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMeetingDate(item)))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }