<template>
    <v-container class="px-4">
        <v-row justify="center">
            <v-col cols="12" sm="10">
                <!-- <Card header-color="cardHeader" action-btn="mdi-calendar" dark @actionBtnClicked="add"> -->
                    <!-- <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Mis reuniones</span>
                    </template> -->
                <Card header-color="cardHeader" action-btn="mdi-format-list-bulleted" dark @actionBtnClicked="showList"
                    :primary-action-disabled="!valid"
                    
                    @primaryAction="save"
                    @secondaryAction="showList"
                    :loading="loading"
                    >
                    <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Reuniones</span>
                    </template>
                    <v-form
                        class="pa-5 caption"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit="save"
                    >
                        <div>
                                    <v-btn
                                        v-if="isDaily"
                                        @click="calendar.type='month'">
                                        Mes 
                                    </v-btn>
                                    <v-btn
                                        v-else
                                        @click="calendar.type='day'">
                                        Día
                                    </v-btn>
                                    <v-btn
                                        class="ml-3"
                                        :class="{primary: isToday}"
                                        @click="setToday">
                                        Hoy
                                    </v-btn>
                                    <v-btn
                                        icon
                                        class="ma-2"
                                        @click="$refs.calendar.prev()"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-btn
                                        icon
                                        class="ma-2"
                                        @click="$refs.calendar.next()"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                    {{currentDateFormatted}}
                                <!-- </v-row> -->
                                <!-- 
                                <v-select
                                    v-model="calendar.type"
                                    :items="calendar.types"
                                    dense
                                    outlined
                                    hide-details
                                    class="ma-2"
                                    label="type"
                                ></v-select>
                                <v-select
                                    v-model="calendar.mode"
                                    :items="calendar.modes"
                                    dense
                                    outlined
                                    hide-details
                                    label="event-overlap-mode"
                                    class="ma-2"
                                ></v-select>
                                <v-select
                                    v-model="calendar.weekday"
                                    :items="calendar.weekdays"
                                    dense
                                    outlined
                                    hide-details
                                    label="weekdays"
                                    class="ma-2"
                                ></v-select>
                                <v-spacer></v-spacer>
                                 -->
                                <v-sheet height="600">
                                    <v-calendar
                                        locale="es"
                                        ref="calendar"
                                        v-model="value"
                                        color="primary"
                                        mode="calendar.mode"
                                        event-more-text='{0} más'
                                        :type="calendar.type"
                                        :weekdays="calendar.weekday"
                                        @click:day="clickDay"
                                        @click:date="clickDate"
                                        :events="computedEvents"
                                        :event-color="getEventColor"
                                        :event-ripple="false"
                                        @change="getEvents"
                                        @click:event="clickEvent"
                                        @mousedown:event="startDrag"
                                        @mousedown:time="startTime"
                                        @mousemove:time="mouseMove"
                                        @mouseup:time="endDrag"
                                        @mouseleave.native="cancelDrag"
                                        >
                                        <!-- <template v-slot:day-body="{day, time}">
                                            asd {{day}}  qwe {{time}}
                                        </template> -->
                                        <template v-slot:event="{ event, timed, eventSummary }">
                                            <!-- <div -->
                                            <!-- :class="{ 'v-event-draggable': calendar.type === 'day'}" -->
                                            <div
                                                
                                                v-html="eventSummary()"
                                            ></div>
                                            <!-- <div
                                            class="v-event-drag-bottom" -->
                                            <div
                                            v-if="timed"
                                            
                                            @mousedown.stop="extendBottom(event)"
                                            >
                                            </div>
                                        </template>
                                    </v-calendar>
                                </v-sheet>
                        </div>
                    </v-form>
                </Card>
            </v-col>
        </v-row>
        <v-row>
            <v-dialog
                v-model="dialog"
                persistent
                max-width="600"
                >
                <v-card>
                    <v-card-title>
                    <span class="headline">Nueva reunión</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col
                            cols="12"
                            sm="8"
                            md="8"
                            centered
                        >
                            <v-text-field
                            label="Título"
                            v-model="createEvent.title"
                            required
                            maxlength="255"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                v-model="showColorSelector"
                                :close-on-content-click="true"
                                :nudge-width="200"
                                slide-y
                                offset-y
                                max-width="150"
                                
                            >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    :color="createEvent.color"
                                    x-small
                                    fab
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-btn>
                            </template>
                                    <v-color-picker
                                        class="ma-2"
                                        hide-canvas
                                        hide-inputs
                                        hide-sliders
                                        :swatches="swatches"
                                        show-swatches
                                        v-model="createEvent.color"
                                    ></v-color-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12"
                            sm="8"
                            md="8"
                            centered>
                            {{formatTime(createEvent)}}
                        </v-col>
                        <v-col
                            cols="12"
                            sm="8"
                            md="8"
                            centered
                        >
                        <v-select
                            class="mt-5"
                            v-model="createEvent.maxParticipants"
                            :items="maxParticipantsOptions"
                            dense
                            hide-details
                            label="Cantidad máxima de participantes"
                        ></v-select>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="8"
                            md="8"
                            centered
                        >
                            <v-text-field
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            label="Contraseña"
                            v-model="createEvent.password"
                            required
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            class="input-group--focused"
                            ></v-text-field>
                        </v-col>
                        <v-col
                            cols="12"
                            sm="8"
                            md="8"
                            centered
                        >
                            <v-textarea
                            rows="2"
                            label="Descripción"
                            v-model="createEvent.description"
                            required
                            maxlength="500"
                            ></v-textarea>
                        </v-col>
                        </v-row>
                    </v-container>
                    
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="cancelCreation"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="confirm123"
                    >
                        Guardar
                    </v-btn>
                    </v-card-actions>
                </v-card>    
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import Card from '@/components/Card.vue';
import utils from '@/utils/utils';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    name: 'Calendar',
    components: {
        Card
    },
    data: () => ({
        showColorSelector: false,
        swatches: [['#E67C73'], ['#F4511E'], ['#F6BF26'], ['#0B8043'], ['#039BE5'], ['#8E24AA']],
        showSwatches: false,
        showPassword: false,
        colorModel: '',
        dialog: false,
        selectedDate: null,
        showCalendar: false,
        // events: [],
        calendar: {
            type: 'month',
            types: [{text: 'Mes', value:'month'}, {text: 'Semana', value: 'week'}, { text: 'Día', value: 'day'} , {text: '4 días', value: '4day'} ],
            mode: 'stack',
            modes: ['stack', 'column'],
            weekday: [1, 2, 3, 4, 5, 6, 0],
            weekdays: [
                { text: 'Lun - Dom', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Lun - Vie', value: [1, 2, 3, 4, 5] },
            ],
            
            
            
        },
        value: '',
        names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
        colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
        // colors: ['#fff'],f
        dragEvent: null,
        dragStart: null,
        createEvent: null,
        emptyEvent: {
            color: null,
            start: null,
            end: null,
            name: 'Sin Título',
            title: '',
            maxParticipants: 300,
        },
        createStart: null,
        extendOriginal: null,
        adding: false,
        model: {
            color: null,
            start: null,
            end: null,
            title: '',
        },
        valid: true,
        loading: false,
        topicRules: [
            v => !!v || 'El título es requerido',
        ],
        // colors: {
        //     FREE: 'green',
        //     USED: 'red'
        // },
        // names: {
        //     FREE: 'Libre',
        //     USED: 'No disponible'
        // },
        categories: ['John Smith', 'Tori Walker'],
    }),
    async created() {
        // this.fetchEvents();
        this.createEvent = { ...this.emptyEvent };
        this.setToday();
        // await this.fetch();
        // const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        // const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
        // const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        // this.value = `${ye}-${mo}-${da}`;
    },
    computed: {
        ...mapState(['meetingsData']),
        ...mapGetters(['currentUser']),
        maxParticipantsOptions() {
            return [300, 500].filter(e => e <= this.currentUser.systemUser.maxMeetingParticipants);
        },
        events() {
            return this.meetingsData.results.map(e => this.eventFromMeeting(e));
        },
        data() {
            return this.meetingsData;
        },
        computedEvents() {
            const events = [...this.events];
            if (this.adding && this.createEvent.start) {
                events.push({...this.createEvent, name: this.createEvent.title || this.createEvent.name});
            }

            return events.map(e => ({ ...e, timed: true }));
        },
        currentDateFormatted() {
            
            if (this.value === '') {
                return '';
            }
            const date = new Date(this.value);
            const month = date.toLocaleString('es', { month: 'long', timeZone: 'UTC'});
            const year = date.getUTCFullYear();
            return this.isDaily
                ? `${date.getUTCDate()} de ${month} de ${year}`
                : `${utils.capitalizeFirstLetter(month)} de ${year}`;
        },
        isToday() {
            return utils.sameDay(new Date(), new Date(this.value));
        },
        isDaily() {
            return this.calendar.type === 'day';
        },
        isPast() {
            return this.isDaily && this.selectedDate.past;
        },
        
    },
    methods: {
        getRandomColor() {
            return this.rndElement (this.swatches)[0];
        },
        canCreateEvent(selectedDate) {
            const now = new Date();
            const minutesDiff = now.getMinutes() % 15;
            const minValidDate = new Date(now - (minutesDiff * 60 + now.getSeconds()) * 1000);
            
            return minValidDate < new Date(`${selectedDate.date} ${selectedDate.time}`);
            // console.log('canCreateEvent', {now, now2});
            // console.log('minutes before', minutes)
            // minutes = parseInt(minutes);
            // minutes -= minutes % 15;"2021-04-29 06:18"
            // console.log('after', {hour, minutes});
            // "2021-04-29 06:18"
            // return this.isDaily && !this.isPast;
        },
        clickEvent () {
            console.log('clickEvent');
        },
        eventFromMeeting(meeting) {
            return {
                    name: meeting.title,
                    color: meeting.color,
                    start: new Date(meeting.start),
                    end: new Date(meeting.end),
                    timed: true,
            }
        },
        ...mapActions(['fetchMeetings', 'deleteMeeting']),
        // async fetch() {
        //     console.log('fetch');
        //     try {
        //         this.loading = true;
        //         await this.fetchMeetings(this.filters);
        //         console.log('meetingsData', this.meetingsData);
        //     } catch (err) {
        //         console.log('fetch err', err);
        //     } finally {
        //         this.loading = false;
        //     }
        // },
        // dayFormat(date, a) {
        //     console.log('dayFormat', {date, a});
        //     return date.;
        // },
        formatTime(event) {
            return utils.formatEvent(event);
        },
        setToday () {
            this.value = utils.formatDate(new Date());
        },
        intervalFormat(interval) {
            return interval.time;
        },
        clickDay(day) {
            this.clickDate(day);
            // this.selectedDay = day;

            // if (day.past) {
            //     console.log('past. ignoring');
            //     return;
            // }
            
        },
        clickDate(date) {
            
            this.selectedDate = date;
            this.value = this.selectedDate.date;
            this.calendar.type = 'day';
        },
        startDrag () {
        // startDrag ({ event, timed }) {
            // console.log('startDrag', { event, timed });
            // if (event && timed) {
                // this.dragEvent = event
                // this.dragTime = null
            //     this.extendOriginal = null
            // }
        },
        startTime (tms) {
            if (!this.canCreateEvent(tms)) {
                return false;
            }
            const mouse = this.toTime(tms)

            if (this.dragEvent && this.dragTime === null) {
                const start = this.dragEvent.start

                this.dragTime = mouse - start
            } else {
                this.createStart = this.roundTime(mouse)
                this.createEvent = {
                    ...this.emptyEvent,
                    color: this.getRandomColor(),
                    start: this.createStart,
                    end: this.createStart,
                    timed: true,
                }
            }
            this.adding = true;
        },
        extendBottom (event) {
            console.log('extendBottom', {event});
            this.createEvent = event
            this.createStart = event.start
            this.extendOriginal = event.end
        },
        mouseMove (tms) {
            
            const mouse = this.toTime(tms)
            if (this.dragEvent && this.dragTime !== null) {
                const start = this.dragEvent.start
                const end = this.dragEvent.end
                const duration = end - start
                const newStartTime = mouse - this.dragTime
                const newStart = this.roundTime(newStartTime)
                const newEnd = newStart + duration

                this.dragEvent.start = newStart
                this.dragEvent.end = newEnd
            } else if (this.createEvent && this.createStart !== null) {
                const mouseRounded = this.roundTime(mouse, false)
                const min = Math.min(mouseRounded, this.createStart)
                const max = Math.max(mouseRounded, this.createStart)
                
                this.createEvent.start = Math.max(min, new Date());
                // this.createEvent.start = min;
                this.createEvent.end = max
            }
        },
        endDrag () {
            // this.model = { ...this.emptyModel };
            // this.model.start = this.createEvent.start;
            // this.model.end = this.createEvent.end;
            // this.model.color = this.createEvent.color;
            // this.model.name = 'asd'
            // this.startCreation();
            
            this.dialog = true;
            this.dragTime = null
            this.dragEvent = null
            // this.createEvent = null
            this.createStart = null
            this.extendOriginal = null
        },
        startCreation() {
            this.dialog = true;
        },
        cancelCreation() {
            this.adding = false;
            this.createEvent = { ...this.emptyEvent };
            this.dialog = false;
        },
        cancelDrag () {
            // console.log('cancelDrag', {a, b})
            if (!this.adding) return this.cancelCreation();
            if (this.createEvent) {
                if (this.extendOriginal) {
                    this.createEvent.end = this.extendOriginal
                } else {
                    const i = this.events.indexOf(this.createEvent)
                    if (i !== -1) {
                        this.events.splice(i, 1)
                    }
                }
            }

            // this.createEvent = { ...this.emptyEvent };
            this.createStart = null
            this.dragTime = null
            this.dragEvent = null
        },
        roundTime (time, down = true) {
            const roundTo = 15 // minutes
            const roundDownTime = roundTo * 60 * 1000

            return down
            ? time - time % roundDownTime
            : time + (roundDownTime - (time % roundDownTime))
        },
        toTime (tms) {
            return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
        },
        getEventColor (event) {
            const rgb = parseInt(event.color.substring(1), 16)
            const r = (rgb >> 16) & 0xFF
            const g = (rgb >> 8) & 0xFF
            const b = (rgb >> 0) & 0xFF

            return event === this.dragEvent
            ? `rgba(${r}, ${g}, ${b}, 0.7)`
            : event === this.createEvent
                ? `rgba(${r}, ${g}, ${b}, 0.7)`
                : event.color
        },
        async getEvents ({ start, end}) {
            await this.fetchMeetings({ from_date: start.date, to_date: end.date });
            // console.log('meetingsData', this.meetingsData);
        },
        async getEvents2 ({ start, end }) {
            await utils.timeout(1000);
            const events = []

            const min = new Date(`${start.date}T00:00:00`).getTime()
            const max = new Date(`${end.date}T23:59:59`).getTime()
            // const days = (max - min) / 86400000;
            // const eventCount = this.rnd(days, days + 20)
            const eventCount = 50;

            for (let i = 0; i < eventCount; i++) {
                const timed = true;
                const firstTimestamp = this.rnd(min, max)
                const secondTimestamp = this.rnd(2, timed ? 8 : 288) * 900000
                const start = firstTimestamp - (firstTimestamp % 900000)
                const end = start + secondTimestamp

                events.push({
                    name: this.rndElement(this.names),
                    color: this.rndElement(this.colors),
                    start,
                    end,
                    timed,
                })
            }

            this.events = events
            
        },
        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },
        rndElement (arr) {
            return arr[this.rnd(0, arr.length - 1)]
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
        fetchEvents ({ start, end }) {
            const events = []

            const min = new Date(`${start.date}T00:00:00`)
            const max = new Date(`${end.date}T23:59:59`)
            // const days = (max.getTime() - min.getTime()) / 86400000
            const eventCount = 2;

            for (let i = 0; i < eventCount; i++) {
            // const allDay = this.rnd(0, 3) === 0
            const allDay = false;
            const firstTimestamp = this.rnd(min.getTime(), max.getTime())
            const first = new Date(firstTimestamp - (firstTimestamp % 900000))
            const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
            const second = new Date(first.getTime() + secondTimestamp)
            const kind = this.rnd(0, this.names.length - 1) === 0 ? 'FREE' : 'USED';
            events.push({
                name: this.names[kind],
                start: first,
                end: second,
                color: this.colors[kind],
                timed: !allDay,
            })
            }

            this.events = events
        },
        ...mapActions(["saveMeeting"]),
        showList() {
            this.$router.push('/reuniones/lista');
        },
        async confirm123() {
            try {
                await this.save();
            } catch (err) {
                console.log('err', err);
            }
        },
        async save() {
            // if (!this.validate()) {
            //     return false;
            // }
            this.loading = true;
            const toSave = {...this.createEvent};
            toSave.title = toSave.title || toSave.name;
            toSave.start = new Date(toSave.start);
            toSave.end = new Date(toSave.end);
            delete toSave.name
            // console.log('saveMeeting', toSave);
            try {
                // await utils.timeout(1);
                // await utils.delayedError(1500);
                // const toSave = {...toSave};
                // toSave.title = toSave.name;
                
                await this.saveMeeting(toSave);
                // await utils.timeout(1000);
                console.log('toSave', toSave);
                this.events.push({...this.createEvent, name: this.createEvent.title || this.createEvent.name});
                this.createEvent = {...this.emptyEvent};
                // await utils.delayedError(500);
                this.$notifications.showSuccess('Reunión guardada.');
                this.adding = false;
                this.dialog = false;
                // this.showList();
            } catch (err) {
                console.log('error', err.response);
                let message = 'Error al guardar.';
                if (err.response?.status === 422 && err.response?.data?.message === 'No account available.') {
                    message = 'El horario seleccionado no está disponible.';
                }
                this.$notifications.showError(message);
            } finally {
                this.loading = false;
            }
            
        },
        validate () {
            return this.$refs.form.validate();
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
        requiredStringRules(text) { 
            return [v => !!v || text];
        },
    },
};
</script>

<style lang="scss">
    div.v-calendar-weekly__day.v-past {
        /* background-color: #bebebe; */
        /* background-color: var(--v-cardHeader-base); */
        cursor: default;
        /* background-color: #f7f7f7; */
    }

    div.v-calendar-weekly__day.v-past .v-btn__content {
        color: rgba(0,0,0,.26)!important;
    }

    div.v-calendar-weekly__day.v-present .theme--light.v-btn.v-btn--has-bg {
        background-color:  var(--v-primary-lighten5);
    }

    div.v-calendar-weekly__day.v-present, div.v-calendar-weekly__day.v-future {
        /* background-color: green; */
        cursor: pointer;
    }


    .v-event-draggable {
    padding-left: 6px;
    }

    .v-event-timed {
    user-select: none;
    -webkit-user-select: none;
    }

    .v-event-drag-bottom {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4px;
        height: 4px;
        cursor: ns-resize;

        &::after {
            display: none;
            position: absolute;
            left: 50%;
            height: 4px;
            border-top: 1px solid white;
            border-bottom: 1px solid white;
            width: 16px;
            margin-left: -8px;
            opacity: 0.8;
            content: '';
        }

        &:hover::after {
            display: block;
        }
    }

    .app-container {
        min-width: 500px;
    }

</style>
