<template>
    <v-dialog
      v-model="dialog"
      width="300"
    >

      <v-card class="py-3">
          <ConfirmDialogSymbol type="warning"></ConfirmDialogSymbol>
        <v-card-title class="justify-center">
          {{title}}
        </v-card-title>

        <v-card-text class="text-center">
            {{text}}
        </v-card-text>

        <v-card-actions>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
                class="mb-0"
            ></v-progress-linear>
            <template v-else>
                <v-spacer></v-spacer>
                <v-btn
                    tile
                    small
                    @click="dialog = false"
                >
                    {{ cancelText || 'Cancelar' }}
                </v-btn>
                <v-btn
                    tile
                    small
                    class="primary"
                    @click.prevent="confirm"
                >
                    {{ confirmText || 'Aceptar' }}
                </v-btn>
                <v-spacer></v-spacer>
            </template>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import ConfirmDialogSymbol from '@/components/ConfirmDialogSymbol.vue';

export default {
    props: ['title', 'text', 'cancelText', 'confirmText', 'loading'],
    components: {
        ConfirmDialogSymbol,
    },
    data: () => ({
        dialog: true,
    }),
    name: "ConfirmDialog",
    methods: {
        confirm() {
            this.$emit('confirm');
        }
    },
    computed: {
        padding() {
            return 5;
        },
    },
    watch: {
        dialog(value) {
            if (!value) {
                this.$emit('cancel');
            }
        }
    }
};
</script>

<style scoped>
.no-margin-top {
  margin-top: 0;
}

.v-navigation-drawer__content .v-list.v-sheet {
  padding-top: 0;
}

.profile-info {
  margin-top: 75px;
  background: rgba(0, 0, 0, 0.37);
  padding: 7px 14px;
  color: #fff;
  position: relative;
  font-size: 13px;
}

.profile-pic {
  padding: 12px;
}

.profile-div {
  display: block;
  margin-bottom: 5px;
  width: 100%;
  background: url(../assets/Observatorio_Bosque_Alegre_02.jpg) no-repeat 0 0;
  background-size: auto;
  background-size: 100%;
}

.active.v-icon {
  /* transform: rotate(-180deg); */
  transform: rotate(180deg) translateY(-1px);
  -webkit-transform: rotate(180deg) translateY(-1px);
}


</style>