<template>
    <v-card tile elevation="0">
        <v-row no-gutters>
            <v-col cols="12">
                <v-data-table dense :headers="headers" :items="items" sort-by="topic" hide-default-footer :item-class="itemRowBackground">
                    <template v-slot:item.actions="{ item }">
                        <span class="actions">
                            <v-hover v-if="_canViewItem(item)" v-slot="{ hover }">
                                <v-icon class="mx-1" :color="hover ? 'primary' : ''" @click="viewItem(item)">mdi-eye</v-icon>
                            </v-hover>
                            <v-hover v-if="_canDeleteItem(item)" v-slot="{ hover }">
                                <v-icon class="mx-1" :color="hover ? 'red accent-4' : ''" @click="deleteItem(item)">mdi-delete</v-icon>
                            </v-hover>
                        </span>
                    </template>
                    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"><slot :name="name" v-bind="slotData" /></template>
                    <template v-slot:no-data>
                        <span><span v-if="!loading">No hay resultados</span></span>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row justify="center" v-if="loading">
            <v-col cols="1" class="text-center">
                <v-progress-circular indeterminate color="primary" size="20"></v-progress-circular>
            </v-col>
        </v-row>
        <ConfirmDialog v-if="dialogDelete" title="¿Eliminar?" text="No podrás revertir esta operación"
            @cancel="closeDelete" @confirm="deleteItemConfirm" :loading="loadingDelete"/>
    </v-card>
</template>

<script>

import ConfirmDialog from '@/components/ConfirmDialog.vue';

export default {
    name: 'GenericTable',
    components: {
        ConfirmDialog,
    },
    props: ['data', 'headers', 'loading', 'loadingDelete', 'canDeleteItem', 'canViewItem'],
    data: () => ({
        dialogDelete: false,
        editedIndex: -1,
    }),

    computed: {
        items() {
            return this.data && this.data.results;
        }
    },

    watch: {
        loadingDelete(val) {
            if (!val) {
                this.closeDelete();
            }
        }
    },

    methods: {
        _canViewItem(item) {
            return !this.canViewItem || this.canViewItem(item);
        },
        _canDeleteItem(item) {
            return !this.canDeleteItem || this.canDeleteItem(item);
        },
        viewItem(item) {
            this.$emit("view", item);
        },
        deleteItem(item) {
            this.$set(item, 'selected', true);
            this.editedItem = item;
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.$emit('delete', this.editedItem);
        },
        closeDelete() {
            this.$set(this.editedItem, 'selected', false);
            this.editedItem = null;
            this.dialogDelete = false;
        },
        itemRowBackground(item) {
            return item.selected ? 'red accent-1' : '';
        }
    }
};
</script>

<style scoped>
.actions .v-icon {
    font-size: 20px;
    color: #bdbdbd;
}
</style>

<style>
.v-data-table table td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}
</style>
