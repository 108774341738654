<template>
    <v-snackbar
        :style="{'margin-top':margin}"
        top
        right
        :multi-line="multiLine"
        :color="type"
        :timeout=timeout
      v-model="snackbar"
    >
    <v-row>
        <span class="body-1">
            <v-icon class="mx-3">mdi-{{icon}}-outline</v-icon>
            {{ text || 'Default text'}}
        </span>
            <v-spacer></v-spacer>
            <v-btn
                class="mybutton"
                icon
                right
                color="white"
                x-small
                @click.prevent="dismiss"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
    </v-row>
    </v-snackbar>
</template>

<script>
export default {
    props: {
        notification: Object,
        // text: String,
        // type: String,
        multiLine: Boolean,
        // timeout: {
        //     type: Number,
        //     default: -1,
        // },
    },
    name: 'Notification',
    data: () => ({
        snackbar: true,
    }),
    computed: {
        icon() {
            switch(this.type) {
                case 'error':
                case 'info':
                    return 'alert-circle';
                case 'success':
                default:
                    return 'information';
            }
        },
        text() {
            return this.notification.text;
        },
        type() {
            return this.notification.type;
        },
        timeout() {
            return this.notification.timeout;
        },
        margin() {
            return (this.notification.i * 80) + 'px'
        },
    },
    methods: {
        dismiss() {
            this.$notifications.dismiss(this.notification);
        },
    },
    watch: {
        snackbar(val) {
            val || this.dismiss();
        }
    }
};
</script>

<style scoped>

</style>