<template>
    <v-container class="px-4">
        <v-row justify="center">
            <v-col cols="12" sm="8">
                <Card header-color="cardHeader" closable @close="showList"
                    :primary-action-text="primaryText"
                    :primary-action-disabled="!valid"
                    secondary-action-text="Volver"
                    @primaryAction="primaryAction"
                    @secondaryAction="showList"
                    :loading="loading"
                    >
                    <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Agregar usuario</span>
                    </template>
                    <v-form
                        class="pa-5 caption"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        @submit="submitForm"
                    >
                        <v-text-field
                        v-model="username"
                        :rules="emailRules"
                        label="Usuario UNC"
                        required
                        autofocus
                        ></v-text-field>

                        <template v-if="userToAdd">
                            <v-text-field
                                :value="userToAdd.names"
                                label="Nombres"
                                disabled
                                ></v-text-field>
                            <v-text-field
                                :value="userToAdd.lastNames"
                                label="Apellidos"
                                disabled
                                ></v-text-field>
                            <v-select
                                class="my-5"
                                    v-model="role"
                                    :items="roles"
                                    dense
                                    hide-details
                                    label="Rol"
                                ></v-select>
                            <v-select
                                class="mt-10"
                                v-model="maxMeetingParticipants"
                                :items="maxParticipantsOptions"
                                dense
                                hide-details
                                label="Cantidad máxima de participantes"
                            ></v-select>
                        </template>
                        <!-- <v-btn
                        :disabled="!valid"
                        color="success"
                        class="mr-4"
                        @click="validate"
                        >
                        Validate
                        </v-btn>

                        <v-btn
                        color="error"
                        class="mr-4"
                        @click="reset"
                        >
                        Reset Form
                        </v-btn>

                        <v-btn
                        color="warning"
                        @click="resetValidation"
                        >
                        Reset Validation
                        </v-btn> -->
                    </v-form>
                        <!-- <v-btn
                            tile
                            block
                            :depressed="loading"
                            :disabled="loading"
                            color="primary lighten-1"
                            @click="loading = !loading"
                            class="caption"
                        >
                            <span v-if="!loading">
                                <v-icon small class="mr-2">mdi-magnify</v-icon>Aplicar filtros
                            </span>
                            <span v-else>
                                <v-progress-circular
                                    class="mr-2"
                                    indeterminate
                                    color="cardHeader"
                                    size="15"
                                ></v-progress-circular>Cargando
                            </span>
                        </v-btn> -->
                </Card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Card from "@/components/Card.vue";
import { mapActions } from "vuex";
// import utils from '@/utils/utils';

export default {
    name: 'NewUser',
    components: {
        Card
    },
    data: () => ({
        maxParticipantsOptions: [300, 500],
        maxMeetingParticipants: 300,
        roles: [{text: 'Usuario estándar', value: 'standard'}, {text: 'Administrador', value: 'admin'}],
        role: {value: 'standard'},
        username: null,
        valid: false,
        loading: false,
        userToAdd: null,
        emailRules: [
            v => !!v || 'El usuario UNC es requerido',
            v => /.+@.+\..+/.test(v) || 'Usuario UNC no válido',
        ],
    }),
    computed: {
        primaryAction() {
            return this.userToAdd ? this.addUser : this.getUser;
        },
        primaryText() {
            return this.userToAdd ? 'Guardar' : 'Obtener datos';
        }
    },
    methods: {
        submitForm() {
            console.log('submitForm');
            this.getUser();
        },
        ...mapActions(['saveUser', 'fetchUsers', 'fetchPersonByUsername']),
        showList() {
            this.$router.push('/usuarios');
        },
        async addUser() {
            console.log('addUser');
            if (!this.userToAdd) return false;
            try {
                await this.saveUser({
                    username: this.username,
                    externalId: this.userToAdd._id,
                    firstNames: this.userToAdd.names,
                    lastNames: this.userToAdd.lastNames,
                    role: this.role.value,
                    maxMeetingParticipants: this.maxMeetingParticipants,
                });
            } catch (err) {
                this.$notifications.showError('Se ha producido un error.');
            }
        },
        async getUser() {
            if(!this.validate()) return;
            this.loading = true;
            try {
                const existingUser = (await this.fetchUsers({username: this.username})).results[0];
                if (existingUser) return this.$notifications.showError('El usuario ya está registrado.');
                
                const person = await this.fetchPersonByUsername(this.username);
                if (!person) return this.$notifications.showError('No se encuentra el usuario.');
                this.userToAdd = person;
                console.log('getUser', person);
                // await utils.timeout(500);
                // await utils.delayedError(1500);
            } catch (err) {
                this.$notifications.showError('No se encuentra el usuario.');
            } finally {
                this.loading = false;
            }
            

            // this.loading = false;
        },
        validate () {
            return this.$refs.form.validate();
        },
        reset () {
            this.$refs.form.reset()
        },
        resetValidation () {
            this.$refs.form.resetValidation()
        },
    },
    watch: {
        username() {
            this.userToAdd = null;
        },
    }
};
</script>
