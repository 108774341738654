import Vue from 'vue';
import VueRouter from 'vue-router';
import NewUser from '@/views/users/NewUser.vue';
import UserList from '@/views/users/UserList.vue';

import NewAccount from '@/views/settings/accounts/NewAccount.vue';
import AccountList from '@/views/settings/accounts/AccountList.vue';

import Calendar from '@/views/meetings/Calendar.vue';
import MeetingList from '@/views/meetings/MeetingList.vue';
import MeetingView from '@/views/meetings/MeetingView.vue';
import NewMeeting from '@/views/meetings/NewMeeting.vue';

import authService from '../auth/authService';
import store from '../store';

Vue.use(VueRouter);

const defaultPath = '/reuniones/lista';

// It can be customized to provide different paths based on user attributes (e.g. permissions)
const getDefaultPath = user => defaultPath;

const unauthorizedPath = '/no-autorizado';

const routes = [
    {
        path: '/',
        component: () => import('../views/Login.vue'),
        meta: {
            requiresAnnonymousUser: true
        }
    },
    {
        path: unauthorizedPath,
        name: 'Unauthorized',
        component: () => import('../views/Unauthorized.vue'),
        meta: {
            requiresAnnonymousUser: true
        }
    },
    {
        path: '/permiso-denegado',
        name: 'Forbidden',
        component: () => import('../views/Forbidden.vue'),
    },
    {
        path: '/usuarios',
        name: 'Users',
        component: () => import('../views/users/Home'),
        meta: {
            requiredRoles: ['admin'],
        },
        children: [
            {
                path: '',
                component: UserList
            },
            {
                path: 'nuevo',
                component: NewUser
            }
        ]
    },
    {
        path: '/reuniones',
        name: 'Meetings',
        component: () => import('../views/meetings/Home'),
        children: [
            {
                path: 'lista',
                component: MeetingList,
            },
            {
                path: 'calendario',
                component: Calendar,
            },
            {
                path: 'nueva',
                component: NewMeeting
            },
            {
                path: ':id',
                component: MeetingView
            },
            {
                path: ':id/editar',
                component: NewMeeting
            }
        ]
    },
    {
        path: '/inicio-de-reunion/:id/',
        name: 'StartMeeting',
        component: () => import('../views/meetings/StartMeetingView'),
        meta: {
            public: true,
        },
    },
    {
        path: '/configuracion/cuentas',
        name: 'Accounts',
        component: () => import('../views/settings/Home'),
        meta: {
            requiredRoles: ['admin'],
        },
        children: [
            {
                path: '',
                component: AccountList
            },
            {
                path: 'nueva',
                component: NewAccount,
            }
        ]
    },
    {
        path: '*',
        component: () => import('../views/PageNotFound'),
        name: 'NotFound',
    }
];

// By default, all routes require auth
routes.forEach(r => {
    if ((!r.meta?.requiresAnnonymousUser && !r.meta?.requiresAuth && !r.meta?.public) || r.meta?.requiredPermissions || r.meta?.requiredRoles) {
        if (!r.meta) {
            r.meta = {};
        }
        r.meta.requiresAuth = true;

        // In case a route is marked as 'requiresAnnonymousUser' or 'public', but also requires permissions or roles
        delete r.meta.requiresAnnonymousUser;
        delete r.meta.public;
    }
});

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    const resolveAuth = to.matched.some(record => record.meta.requiresAuth || record.meta.requiresAnnonymousUser);
    const fn = () => {
        if (resolveAuth && authService.forceLogin && to.path !== '/') {
            return next({ path: '/', query: { t: to.fullPath } });
        }

        if (to.path === '/' && !authService.forceLogin) {
            return next({ path: getDefaultPath(authService.user) });
        }

        if (to.matched.some(record => record.meta.requiresAuth) && !authService.validUser) {
            return next({ name: 'Unauthorized', query: { t: to.fullPath } });
        } else if (to.matched.some(record => record.meta.requiredPermissions || record.meta.requiredRoles) && !authService.user.hasAuthorities(to.matched)) {
            return next({ name: 'Forbidden', query: { t: to.path } });
        }
        next();
    };

    if (resolveAuth) {
        authService.login();
    } else {
        // Set as ready on public routes.
        authService.ready = true;
    }

    if (!authService.loading) {
        return fn();
    }

    authService.$watch('loading', loading => {
        if (!loading && !authService.error) {
            return fn();
        }
    });
});

export default router;
