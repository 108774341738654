<template>
    <v-container class="px-4">
        <v-row justify="center">
            <v-col cols="12" sm="3" class="mr-10 mb-10">
                <Card header-color="#f7f7f7" title="Criterios de búsqueda">
                    <!-- <template slot="title">
                        <div class="mx-auto text-centered" style="width: 200px;">
                            <span class="button text-centered" style="font-size: 17px;">Criterios de búsqueda</span>
                        </div>
                    </template> -->
                    <v-form class="pa-5 caption"
                        @submit="fetch"
                    >
                        <v-text-field
                            v-model="filters.email"
                            label="Cuenta"
                            class="pt-0"
                        ></v-text-field>
                        <v-select
                            v-model="filters.externallyActive"
                            :items="externallyActiveOptions"
                            label="Vigente"
                            data-vv-name="selectActive"
                            required
                        ></v-select>
                        <v-select
                            v-model="filters.enabled"
                            :items="enabledOptions"
                            label="Habilitada"
                            data-vv-name="selectEnabled"
                            required
                        ></v-select>
                        
                        <v-btn
                            tile
                            block
                            :depressed="loading"
                            :disabled="loading"
                            color="primary lighten-1"
                            @click="fetch"
                            class="caption"
                        >
                            <span v-if="!loading">
                                <v-icon small class="mr-2">mdi-magnify</v-icon>
                                Aplicar filtros
                            </span>
                            <span v-else>
                                <v-progress-circular
                                    class="mr-2"
                                    indeterminate
                                    color="cardHeader"
                                    size="15"
                                ></v-progress-circular>Cargando
                            </span>
                        </v-btn>
                    </v-form>
                </Card>
            </v-col>
            <v-col cols="12" sm="7" class="card">
                <Card header-color="cardHeader" action-btn="mdi-plus" dark @actionBtnClicked="addUser">
                    <template slot="title">
                        <span class="button pl-3 text-uppercase white--text" style="font-size: 17px;">Cuentas</span>
                    </template>
                    <GenericTable :headers="headers" :data="data" :loading="loading" :loading-delete="loadingDelete" @deleteItem="deleteItem" :canViewItem="() => false">
                        <template v-slot:item.externallyActive="{ item }">
                            <div class="d-flex justify-center">
                                <v-checkbox readonly :input-value="item.externallyActive"></v-checkbox>
                            </div>
                        </template>
                        <template v-slot:item.enabled="{ item }">
                            <div class="d-flex justify-center">
                                <v-checkbox readonly :input-value="item.enabled"></v-checkbox>
                            </div>
                        </template>
                    </GenericTable>
                </Card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Card from "@/components/Card.vue";
import GenericTable from "@/components/GenericTable.vue";
import { mapActions, mapState } from "vuex";
import utils from '@/utils/utils';

export default {
    name: "UserList",
    components: {
        GenericTable,
        Card
    },
    data: () => ({
        filters: {
            email: undefined,
            externallyActive: undefined,
        },
        loading: true,
        headers: [
            {
                text: "Cuenta",
                align: "start",
                sortable: false,
                value: "email"
            },
            { text: "Límite de participantes", value: "maxMeetingParticipants", align: 'center' },
            { text: "Vigente", value: "externallyActive", align: 'center' },
            { text: "Habilitada", value: "enabled", align: 'center' },
            { text: "", value: "actions", sortable: false, align: "end" }
        ],
        loadingDelete: false,
    }),
    async created() {
        await this.fetch();
    },
    methods: {
        ...mapActions(['fetchAccounts', 'deleteAccount']),
        async fetch() {
            try {
                this.loading = true;
                await this.fetchData(this.filters);
            } catch (err) {
                console.log('fetch err', err);
            } finally {
                this.loading = false;
            }
        },
        addUser() {
            console.log('addUser');
            this.$router.push('/settings/accounts/new');
        },
        async deleteItem(item) {
            console.log('deleteItem', item);
            this.loadingDelete = true;
            try {
                await utils.timeout(500);
                // await utils.delayedError(500);
                // await utils.randomAsync(500);
                await this.deleteAccount(item.id);
                const index = this.items.findIndex(e => e.id === item.id);
                console.log({index});
                if (index !== -1) {
                    this.items.splice(index, 1);
                }
                this.$notifications.showSuccess('Se eliminó la cuenta.');
            } catch (err) {
                console.log('deleteItem error', err.response);
                this.$notifications.showError('Error al eliminar.');
            } finally {
                this.loadingDelete = false;
            }
            
        }
    },
    computed: {
        ...mapState(["accountsData"]),
        data() {
            return this.accountsData;
        },
        items() {
            return this.data && this.data.results;
        },
        fetchData() {
            return this.fetchAccounts;
        },
        enabledOptions() {
            return [
                {text: 'Todas', value: '__all__'},
                {text: 'Habilitadas', value: true},
                {text: 'No habilitadas', value: false},
            ]
        },
        externallyActiveOptions() {
            return [
                {text: 'Todas', value: '__all__'},
                {text: 'Vigentes', value: true},
                {text: 'No vigentes', value: false},
            ]
        },
        cleanedFilters() {
            return this.filters
        }
    }
};
</script>
